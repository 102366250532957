import React, { useEffect } from "react";
import { connect } from "react-redux";
import CurrentPricing from "./CurrentPricing";
import PricingHistory from "./PricingHistory";
import { startGetAppSettings } from "../../redux/appSettings/appSettingsActions";
import TrialSettings from "./TrialSettings";
import TrialHistory from "./TrialHistory";
import Features from "./Features";
import FeatureHistory from "./FeatureHistory";
import OneTimeConfigSettings from "./OneTimeConfigSettings";
import { Container } from "reactstrap";

const AppSettings = (props) => {
  const { appSettings } = props;
  useEffect(() => {
    props.startGetAppSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      {appSettings ? (
        <div>
          <CurrentPricing />
          <PricingHistory />
          <TrialSettings />
          <TrialHistory />
          <Features />
          <FeatureHistory />
          <OneTimeConfigSettings />
        </div>
      ) : (
        <div>Loading</div>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    appSettings: state.appSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startGetAppSettings: () => dispatch(startGetAppSettings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppSettings);
