import React, { useEffect } from "react";
import { connect } from "react-redux";
import Header from "./Header";
import SideView from "./SideView";
import Dashboard from "../dashboard/Dashboard";
import Users from "../users/Users";
import AppSettings from "../appSettings/AppSettings";
import Reports from "../reports/Reports";
import {
  DASHBOARD,
  PAYMENTS,
  USERS,
  APP_SETTINGS,
  REPORTS,
  DELUSERS,
  REF_SETTINGS,
} from "../viewTypes";
import { setView } from "../../redux/setView/setViewActions";
import Payments from "../payments/Payments";
import _ from "lodash";
import { startLogout } from "../../redux/auth/authActions";
import DeletedUsers from "../deletedUsers/DeletedUsers";

import { Button, Container } from "reactstrap";
import RefSettings from "../refSettings/RefSettings";

const ContainerPage = (props) => {
  const { view, userInfo } = props;

  useEffect(() => {
    props.setView(DASHBOARD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !_.isEmpty(userInfo) &&
      userInfo.role !== "admin" &&
      userInfo.role !== "superAdmin"
    ) {
      setTimeout(() => {
        props.startLogout();
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const handleClick = () => {
    props.startLogout();
  };

  //console.log("User Info", userInfo);

  return (
    <div className="pageColor">
      {!_.isEmpty(userInfo) &&
      (userInfo.role === "superAdmin" || userInfo.role === "admin") &&
      userInfo.emailVerified ? (
        <div>
          <Header />

          <div className="sidenav sidenavBorder pr-1 col-fixed">
            <SideView />
          </div>
          <div className="col-fluid scroll-content">
            <Container fluid={true} className="container-fluid">
              <Container className="boarder boarder-primary shadow mt-4 p-3">
                <h5>
                  {userInfo.firstName} {userInfo.lastName}
                </h5>
                <h6>{userInfo.email}</h6>
                <h6>{userInfo.role}</h6>
              </Container>
              {view === DASHBOARD && <Dashboard />}
              {view === PAYMENTS && <Payments />}
              {view === USERS && <Users />}
              {view === DELUSERS && <DeletedUsers />}
              {view === APP_SETTINGS && <AppSettings />}
              {view === REPORTS && <Reports />}
              {view === REF_SETTINGS && <RefSettings />}
            </Container>
          </div>
        </div>
      ) : (
        <Container>
          <h5>You Have No permission to access the service</h5>
          <Button color="primary" onClick={handleClick}>
            OK
          </Button>
        </Container>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    view: state.view.mainView,
    //auth: state.auth,
    userInfo: state.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setView: (view) => dispatch(setView(view)),
    startLogout: () => dispatch(startLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContainerPage);
