import _ from "lodash";
const sortByDate = (list) => {
  return (
    !_.isEmpty(list) &&
    list.sort((a, b) => {
      return a.date < b.date ? 1 : -1;
    })
  );
};

export default sortByDate;
