import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  //AgGridColumn,
  AgGridReact,
} from "ag-grid-react";
import moment from "moment";
import sortByDate from "../../selectors/sortByDate";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

const TrialHistory = (props) => {
  const { trialHistory } = props;

  // const [gridApi, setGridApi] = useState(null);
  // const [gridColumnApi, setGridColumnApi] = useState(null);

  //console.log("Pricing History", pricingHistory);

  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([]);

  function onGridReady(params) {
    // setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
  }

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Date",
        field: "date",
        resizable: true,
      },
      {
        headerName: "New Trial Period",
        field: "newTrialPeriod",
        resizable: true,
        width: 150,
      },
      {
        headerName: "Old Trial Period",
        field: "oldTrialPeriod",
        resizable: true,
        width: 150,
      },

      { headerName: "Reason", field: "reason", resizable: true, width: 300 },
      {
        headerName: "Modified By",
        field: "modifiedBy",
        resizable: true,
        width: 240,
      },
    ]);
  }, []);

  useEffect(() => {
    const temp =
      trialHistory.length > 0
        ? trialHistory.map((history) => ({
            date: moment(history.date).format(),
            newTrialPeriod: history.newTrialPeriod,
            oldTrialPeriod: history.oldTrialPeriod,
            reason: history.reason,
            modifiedBy: history.modifiedBy,
          }))
        : "";
    setRowData(temp);
  }, [trialHistory]);

  return (
    <div
      className="ag-theme-balham left-align"
      style={{ height: 240, width: "100%", boxSizing: "border-box" }}
    >
      <AgGridReact
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        rowData={rowData}
        enableFilter={true}
        enableSorting={true}
        pagination="true"
        paginationPageSize="5"
      ></AgGridReact>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    trialHistory: sortByDate(state.appSettings.trialHistory),
  };
};

export default connect(mapStateToProps)(TrialHistory);
