import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  //AgGridColumn,
  AgGridReact,
} from "ag-grid-react";
import numFormat from "../../selectors/numFormat";
import sortByDate from "../../selectors/sortByDate";
import _ from "lodash";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

const PaymentHistoryTable = (props) => {
  const { paymentHistory } = props;
  const { email } = props;

  // const [gridApi, setGridApi] = useState(null);
  // const [gridColumnApi, setGridColumnApi] = useState(null);

  //console.log("Payment History", paymentHistory);

  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([]);

  function onGridReady(params) {
    // setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
  }

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Date",
        field: "date",
        resizable: true,
        width: 250,
        suppressSizeToFit: true,
      },
      {
        headerName: "Email",
        field: "email",
        resizable: true,
        width: 250,
        suppressSizeToFit: true,
      },
      { headerName: "Amount", field: "amount", resizable: true },
      { headerName: "Subscription", field: "subscription", resizable: true },
    ]);
  }, []);

  useEffect(() => {
    let tempPay = !_.isEmpty(paymentHistory) ? paymentHistory : [];
    if (email) {
      tempPay =
        !_.isEmpty(paymentHistory) &&
        paymentHistory.filter((pay) => pay.userEmail === email);
    }
    //console.log("Temp Pay", tempPay);
    const temp = !_.isEmpty(tempPay)
      ? tempPay.map((paymentHistoryItem) => ({
          date: paymentHistoryItem.paymentDate,
          email: paymentHistoryItem.userEmail,
          amount: numFormat(paymentHistoryItem.amount),
          subscription:
            paymentHistoryItem.plan[0].toUpperCase() +
            paymentHistoryItem.plan.slice(1),
        }))
      : [];
    setRowData(temp);
  }, [paymentHistory, email]);

  return (
    <div
      className="ag-theme-balham left-align"
      style={{ height: 400, width: "100%", boxSizing: "border-box" }}
    >
      <AgGridReact
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        rowData={rowData}
        enableFilter={true}
        enableSorting={true}
        pagination="true"
        paginationPageSize="10"
        //floatingFilter="true"
      ></AgGridReact>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    paymentHistory: sortByDate(state.paymentHistory.filteredPaymentHistory),
  };
};

export default connect(mapStateToProps)(PaymentHistoryTable);
