import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { startGetUsersList } from "../../redux/users/usersActions";
import UsersList from "./UsersList";
import UserSearch from "./UserSearch";
import UserDetails from "./UsersDetails";
import UserActions from "./UserActions";
import _ from "lodash";
import SentEmailToUser from "./SentEmailToUser";
import { sortEmailAndFilter } from "../../selectors/sortEmailAndFilter";
import { Container } from "reactstrap";

const Users = (props) => {
  const { showCustomerDetails, usersList } = props;
  useEffect(() => {
    props.startGetUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCustomerDetails]);

  const [rows, setRows] = useState([]);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [emailList, setEmailList] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(usersList)) {
      setRows(usersList);
      setUpdatedRows(usersList);
      const temp = usersList.map((item) => item.email);
      const temp1 = sortEmailAndFilter(temp);

      setEmailList(temp1);
    }
  }, [usersList]);

  const onRowUpdate = (updatedUserList) => {
    if (!_.isEmpty(updatedUserList)) {
      setUpdatedRows(updatedUserList);
    } else setUpdatedRows([]);
  };

  return (
    <Container>
      {showCustomerDetails ? (
        <UserDetails />
      ) : (
        <Fragment>
          <UserSearch rows={rows} onRowUpdate={onRowUpdate} />
          {emailList && (
            <SentEmailToUser emailList={emailList} usersList={usersList} />
          )}

          <UserActions rows={rows} onRowUpdate={onRowUpdate} />
          <UsersList rows={updatedRows} />
        </Fragment>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    showCustomerDetails: state.view.showCustomerDetails,
    usersList: state.users.usersList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startGetUsersList: () => dispatch(startGetUsersList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
