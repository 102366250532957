import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import {
  doGetReferralList,
  doUpdateReferralList,
} from "../../redux/referral/referralActions";
import _ from "lodash";
import ReferralItemsReadOnly from "./ReferralItemsReadOnly";
import EditReferralItem from "./EditReferralItem";

const ReferralTable = (props) => {
  const { referralList, userInfo } = props;
  const [editRefID, setEditRefID] = useState(null);

  useEffect(() => {
    props.doGetReferralList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editReferral = (refID) => {
    setEditRefID(refID.toString());
  };

  const onCancelEdit = () => {
    setEditRefID(null);
  };

  const saveRefEdit = (refDataEdited) => {
    const newList = [...referralList];
    const index = referralList.findIndex(
      (listItem) => listItem.refID === editRefID
    );
    newList[index] = {
      ...refDataEdited,
      lastModifiedDate: new Date().toISOString(),
      modifiedBy: userInfo.email + "-" + userInfo.role,
    };
    props.doUpdateReferralList(newList);
    onCancelEdit();
  };

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Contact Person</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Reference Code</th>
            <th>Notes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!_.isEmpty(referralList) &&
            referralList.map((listItem) => (
              <Fragment key={listItem.refID}>
                {editRefID === listItem.refID ? (
                  <EditReferralItem
                    refDataToEdit={listItem}
                    saveRefEdit={saveRefEdit}
                    onCancelEdit={onCancelEdit}
                  />
                ) : (
                  <ReferralItemsReadOnly
                    listItem={listItem}
                    editReferral={editReferral}
                  />
                )}
              </Fragment>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    referralList: state.referralList.referralList,
    userInfo: state.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetReferralList: () => dispatch(doGetReferralList()),
    doUpdateReferralList: (newReferralList) =>
      dispatch(doUpdateReferralList(newReferralList)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralTable);
