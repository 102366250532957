import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { searchUserByEmail } from "../../redux/users/usersActions";
import _ from "lodash";
import { Button, Label, Alert, Col, Row } from "reactstrap";
import { Combobox, DropdownList } from "react-widgets";
import { validateEmail } from "../../validation/auth";
import axios from "axios";
import { sendNotificationEmail } from "../serviceURLs/emailServices";

const SentEmailToUser = (props) => {
  const { emailList, usersList } = props;

  const formRef = useRef();

  const emailForOptions = [
    {
      name: "Subscription expires in 7 days",
      value: "sub7days",
      type: "subscribed",
      daysToExpire: "7",
    },
    {
      name: "Subscription expires in 2 days",
      value: "sub2days",
      type: "subscribed",
      daysToExpire: "2",
    },
    {
      name: "Subscription expires in 1 days",
      value: "sub1days",
      type: "subscribed",
      daysToExpire: "1",
    },
    {
      name: "Subscription expires today",
      value: "sub0days",
      type: "subscribed",
      daysToExpire: "0",
    },
    {
      name: "Trial epires in 7 days",
      value: "trial7days",
      type: "trial",
      daysToExpire: "7",
    },
    {
      name: "Trial expires in 2 days",
      value: "trial2days",
      type: "trial",
      daysToExpire: "2",
    },
    {
      name: "Trial expires in 1 days",
      value: "trial1days",
      type: "trial",
      daysToExpire: "1",
    },
    {
      name: "Trial expires today",
      value: "trial0days",
      type: "trial",
      daysToExpire: "0",
    },
  ];

  const [sendEmailTo, setSendEmailTo] = useState();
  const [sendEmailFor, setSendEmailFor] = useState();
  const [errorMsg, setErrorMsg] = useState("");

  const onSendEmailToChanged = (value) => {
    //console.log("email selected", value);
    setErrorMsg("");
    setSendEmailTo(value);
  };

  const onSendEmailForChanged = (value) => {
    //console.log("email reason selected", value);
    setErrorMsg("");
    setSendEmailFor(value);
  };

  const sendEmailBody = () => {
    let body = {};
    let userSelected = {};
    if (!_.isEmpty(usersList)) {
      userSelected = usersList.find((user) => user.email === sendEmailTo);
      body = userSelected
        ? {
            to: sendEmailTo,
            subject: "Easy Budgets Expiration Notification",
            message: `<p>Dear ${userSelected.firstName} ${userSelected.lastName},
    <p>Your Easy Budgets ${sendEmailFor.type} period expiring in ${sendEmailFor.daysToExpire} days, please subscribe or renew your subscription today to continue using <strong>Easy Budgets<strong>!</p>
    <p>regards</p><p><strong>Easy Budgets Team<strong></p>`,
          }
        : {};
    }
    return body;
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    formRef.current.reset();
    if (validateEmail(sendEmailTo) && sendEmailFor) {
      const sendEmailResult = await axios.post(
        sendNotificationEmail,
        sendEmailBody()
      );
      if (sendEmailResult.error) {
        console.log("Error sending email");
      } else {
        console.log("Result", sendEmailResult.data);
      }
    } else {
      setErrorMsg("Invalid Email or Option Selected!");
    }
  };

  return (
    <form ref={formRef}>
      <Row>
        <Col sm="3">
          <Label>Sent Email To</Label>
          <Combobox
            className="custom-dropdown"
            data={emailList}
            filter="contains"
            onChange={onSendEmailToChanged}
            placeholder="Select the Email"
            value={sendEmailTo || ""}
          />
        </Col>
        <Col sm="4">
          <Label>Send Email For</Label>
          <DropdownList
            className="custom-dropdown"
            data={emailForOptions}
            placeholder="Select the Email for"
            textField="name"
            onChange={onSendEmailForChanged}
            //value={sendEmailFor && setSendEmailFor.name}
          />
        </Col>
        <Col sm="2" className="mt-2">
          <Button
            color="primary"
            size="sm"
            className="mt-4"
            onClick={sendEmail}
            disabled={sendEmailTo && sendEmailFor ? false : true}
          >
            Send Email
          </Button>
        </Col>
        <Col sm="3">{errorMsg && <Alert color="danger">{errorMsg}</Alert>}</Col>
      </Row>
      <br />
    </form>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchUserByEmail: (email) => dispatch(searchUserByEmail(email)),
    // searchPaymentHistoryByDates: (startDate, endDate) =>
    //   dispatch(searchPaymentHistoryByDates(startDate, endDate)),
  };
};

export default connect(undefined, mapDispatchToProps)(SentEmailToUser);
