import {
  LOGIN,
  LOGOUT,
  LOGIN_ERROR,
  AUTH_MESSAGE,
  CLEAR_AUTH_MESSAGES,
} from "./authTypes";

const initialState = {
  authError: "",
  authMessage: "",
};

const authReducer = (state = initialState, action) => {
  //console.log("Action", action);
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        authError: "",
        uid: action.uid,
        emailVerified: action.emailVerified,
      };
    case LOGOUT:
      return {};
    case LOGIN_ERROR:
      return {
        ...state,
        authError:
          "Email address or password entered are wrong! Please try again",
      };

    case AUTH_MESSAGE:
      return {
        ...state,
        authError: "",
        authMessage: action.authMessage,
      };

    case CLEAR_AUTH_MESSAGES:
      return {
        ...state,
        authMessage: "",
        authError: "",
      };

    default:
      return state;
  }
};

export default authReducer;
