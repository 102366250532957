import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => (
  <div>
    404! - <Link to="/login">Go Home</Link>
  </div>
);

export default PageNotFound;
