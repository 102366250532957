import { SET_REFERRAL, UPDATE_REFERRAL, MSG, CLEAR_MSG } from "./referralTypes";

const initialState = {
  referralList: [],
  msg: "",
};

const referralReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REFERRAL:
      return {
        ...state,
        referralList: action.referralList,
      };
    case UPDATE_REFERRAL:
      return {
        ...state,
        referralList: [...state.referralList, action.refData],
      };
    case CLEAR_MSG:
      return { ...state, msg: "" };
    case MSG:
      return { ...state, msg: action.msg };
    default:
      return state;
  }
};

export default referralReducer;
