import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

const UserSection = (props) => {
  const { userStat } = props;

  return (
    <Fragment>
      {userStat && (
        <Row>
          <Col sm="2" className="border border-primary shadow m-1 p-2">
            <center>
              TOTAL USERS:
              <h5 className="mt-2">{userStat.totalUsers}</h5>
            </center>
          </Col>
          <Col sm="2" className="border border-primary shadow m-1 p-2">
            <center>
              TOTAL ACTIVE USERS:
              <h5 className="mt-2">{userStat.totalActiveUsers}</h5>
            </center>
          </Col>
          <Col sm="2" className="border border-primary shadow m-1 p-2">
            <center>
              ACTIVE SUBSCRIBED USERS:
              <h5 className="mt-2">{userStat.totalActiveSubscribedUsers}</h5>
            </center>
          </Col>
          <Col sm="2" className="border border-primary shadow m-1 p-2">
            <center>
              ACTIVE TRIAL USERS:
              <h5 className="mt-2">{userStat.totalActiveTrialUsers}</h5>
            </center>
          </Col>
          <Col sm="2" className="border border-primary shadow m-1 p-2">
            <center>
              TOTAL DISABALED USERS:
              <h5 className="mt-2">{userStat.totalDisabledUsers}</h5>
            </center>
          </Col>

          <Col sm="2" className="border border-primary shadow m-1 p-2">
            <center>
              TOTAL EXPIRED USERS:
              <h5 className="mt-2">{userStat.totalExpiredUsers}</h5>
            </center>
          </Col>
          <Col sm="2" className="border border-primary shadow m-1 p-2">
            <center>
              TOTAL DELETED USERS:
              <h5 className="mt-2">{userStat.totalDeletedUsers}</h5>
            </center>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userStat: state.dashboard.userStat,
  };
};

export default connect(mapStateToProps)(UserSection);
