export const reservedKeyWords = [
  "credit",
  "to be budgeted",
  "tobebudgeted",
  "to bebudgeted",
  "tobe budgeted",
  "debit",
  "balance adjustment",
  "balanceadjustment",
  "manual adjustment",
  "manualadjustment",
  "opening balance",
  "openingbalance",
  "uncategorized",
  "uncategorised",
  "starting balance",
  "startingbalance",
  "transfer",
  "transfer:",
  "transfer :"
];
