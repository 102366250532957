export const categoryList = [
  {
    categoryName: "Monthly Bills",
    subcategoryNames: [
      "Phone",
      "Internet",
      "Cable",
      "Electricity",
      "Rent",
      "Water",
    ],
  },
  {
    categoryName: "Monthly Expenses",
    subcategoryNames: [
      "Groceries",
      "Fuel",
      "Medical",
      "Auto Maintenance",
      "Home Maintenance",
      "Auto Insurance",
      "Home Insurance",
      "Clothing",
      "Gifts",
      "Restaurants",
      "Movie",
    ],
  },
  {
    categoryName: "Investments and Savings",
    subcategoryNames: ["Savings", "Gold", "Investments"],
  },
  {
    categoryName: "Goals",
    subcategoryNames: ["Vacation", "Education"],
  },
  {
    categoryName: "Tax",
    subcategoryNames: ["Property Tax", "Income Tax Returns"],
  },
  {
    categoryName: "Loans",
    subcategoryNames: [
      "Auto Loan",
      "Mortgage",
      "Student Loan",
      "Personal Loan",
    ],
  },
];

export const appCategoryList = [
  {
    categoryName: "Credit",
    subcategoryNames: ["To be Budgeted"],
  },
];
