import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
//import logger from "redux-logger";
import { rootReducer } from "./rootReducer";

//Dev
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //addition to preserve the dev tool

//Prod
//const composeEnhancers = compose; //addition to preserve the dev tool

const composeEnhancers =
  process.env.REACT_APP_ENV === "DEV"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const store = createStore(
  rootReducer,
  //composeEnhancers(applyMiddleware(logger, thunk))
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
