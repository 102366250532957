import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { validAmount } from "../../validation/amount";
import { validPercentage } from "../../validation/percentage";
import {
  startUpdateAppPricing,
  setPriceMsg,
} from "../../redux/appSettings/appSettingsActions";
import {
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  Alert,
} from "reactstrap";
import _ from "lodash";

const CurrentPricing = (props) => {
  const { currentPricing, userInfo, msg } = props;
  const [pricingData, setPricingData] = useState();
  const [currency] = useState(
    process.env.REACT_APP_USER_REGION === "INDIA" ? "(₹)" : "($)"
  );

  useEffect(() => {
    if (!_.isEmpty(currentPricing) && !_.isEmpty(userInfo)) {
      setPricingData({
        date: new Date().toISOString(),
        newMonthlyPrice: currentPricing.monthly.price / 100 || "",
        newYearlyPrice: currentPricing.yearly.price / 100 || "",
        oldMonthlyPrice: currentPricing.monthly.price / 100 || "",
        oldYearlyPrice: currentPricing.yearly.price / 100 || "",
        oldTax: currentPricing.tax / 100 || "",
        newTax: currentPricing.tax / 100 || "",
        reason: "",
        modifiedBy: userInfo.email + "-" + userInfo.role,
      });
      props.setPriceMsg("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPricing, userInfo]);

  const onMonthlyPriceChange = (e) => {
    const newMonthlyPrice = e.target.value;
    validAmount(newMonthlyPrice) &&
      setPricingData({ ...pricingData, newMonthlyPrice });
    //console.log("Value", newMonthlyPrice);
  };

  const onYearlyPriceChange = (e) => {
    const newYearlyPrice = e.target.value;
    validAmount(newYearlyPrice) &&
      setPricingData({ ...pricingData, newYearlyPrice });
  };

  const taxChanged = (e) => {
    const newTax = e.target.value;
    validPercentage(newTax) && setPricingData({ ...pricingData, newTax });
  };

  const onReasonChange = (e) => {
    const reason = e.target.value;
    setPricingData({ ...pricingData, reason });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      pricingData.newMonthlyPrice !== pricingData.oldMonthlyPrice ||
      pricingData.newYearlyPrice !== pricingData.oldYearlyPrice ||
      pricingData.newTax !== pricingData.oldTax
    ) {
      pricingData.newMonthlyPrice =
        parseFloat(pricingData.newMonthlyPrice, 10) * 100;
      pricingData.newYearlyPrice =
        parseFloat(pricingData.newYearlyPrice, 10) * 100;
      pricingData.oldMonthlyPrice =
        parseFloat(pricingData.oldMonthlyPrice, 10) * 100;
      pricingData.oldYearlyPrice =
        parseFloat(pricingData.oldYearlyPrice, 10) * 100;
      pricingData.oldTax = parseFloat(pricingData.oldTax, 10) * 100 || 0;
      pricingData.newTax = parseFloat(pricingData.newTax, 10) * 100 || 0;
      //console.log("Final Update", pricingData);
      props.startUpdateAppPricing(pricingData);
    } else props.setPriceMsg("No Changes to Update");
  };

  return (
    <Fragment>
      <Container className="mt-3">
        <h5>App Pricing</h5>
        <Row>
          <Col sm="2">
            <FormGroup>
              <Label> Monthly Price {currency} </Label>
              <Input
                type="text"
                className="text-right"
                value={pricingData ? pricingData.newMonthlyPrice : ""}
                onChange={onMonthlyPriceChange}
                placeholder="0"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup>
              <Label> Yearly Price {currency}</Label>
              <Input
                type="text"
                className="text-right"
                value={pricingData ? pricingData.newYearlyPrice : ""}
                onChange={onYearlyPriceChange}
                placeholder="0"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            {
              <FormGroup>
                <Label> Tax (%)</Label>
                <Input
                  type="text"
                  className="text-right"
                  value={pricingData ? pricingData.newTax : ""}
                  onChange={taxChanged}
                  placeholder="0"
                />
              </FormGroup>
            }
          </Col>
          <Col sm="3">
            <FormGroup>
              <Label> Reason for Update</Label>
              <Input
                type="text"
                value={pricingData ? pricingData.reason : ""}
                onChange={onReasonChange}
                placeholder="Reason for change"
              />
            </FormGroup>
          </Col>
          <Col sm="2" className="mt-2">
            <Button className="mt-3" color="primary" onClick={onSubmit}>
              Update
            </Button>
          </Col>
        </Row>
        {msg && <Alert color="warning">{msg}</Alert>}
      </Container>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    currentPricing: state.appSettings.currentPricing,
    msg: state.appSettings.pricemsg,
    userInfo: state.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startUpdateAppPricing: (pricingData) =>
      dispatch(startUpdateAppPricing(pricingData)),
    setPriceMsg: (msg) => dispatch(setPriceMsg(msg)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPricing);
