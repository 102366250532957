import { SET_USERINFO } from "./userInfoTypes";

const initialState = {};

const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERINFO:
      return action.userInfo;
    default:
      return state;
  }
};

export default userInfoReducer;
