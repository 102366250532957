import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  startUpdateFeatureList,
  setFeatureMsg,
} from "../../redux/appSettings/appSettingsActions";
import {
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  Alert,
} from "reactstrap";

const Features = (props) => {
  const { featureList, userInfo, msg } = props;

  const initValue = {
    date: new Date().toISOString(),
    allowSignUp: false,
    allowLogin: false,
    allowExport: false,
    allowImport: false,
    allowedBudgets: "",
    allowedAccounts: "",
    modifiedBy: "",
  };

  const [featureData, setFeatureData] = useState(initValue);

  useEffect(() => {
    !_.isEmpty(featureList) &&
      setFeatureData({
        date: new Date().toISOString(),
        allowSignUp: featureList.allowSignUp || false,
        allowLogin: featureList.allowLogin || false,
        allowExport: featureList.allowExport || false,
        allowImport: featureList.allowImport || false,
        allowedBudgets: featureList.allowedBudgets || 0,
        allowedAccounts: featureList.allowedAccounts || 0,
        modifiedBy: userInfo.email + "-" + userInfo.role,
      });
  }, [featureList, userInfo]);

  const clearMsg = () => {
    props.setFeatureMsg("");
  };

  const onAllowSignUpChanged = () => {
    setFeatureData({
      ...featureData,
      allowSignUp: !featureData.allowSignUp,
    });
    clearMsg();
  };

  const onallowLoginChanged = () => {
    setFeatureData({
      ...featureData,
      allowLogin: !featureData.allowLogin,
    });
    clearMsg();
  };

  const onAllowExportChanged = () => {
    setFeatureData({
      ...featureData,
      allowExport: !featureData.allowExport,
    });
    clearMsg();
  };

  const onAllowImportChanged = () => {
    setFeatureData({
      ...featureData,
      allowImport: !featureData.allowImport,
    });
    clearMsg();
  };

  const onAllowedBudgetsChanged = (e) => {
    const allowedBudgets = e.target.value;
    setFeatureData({ ...featureData, allowedBudgets });
    clearMsg();
  };

  const onAllowedAccountsChanged = (e) => {
    const allowedAccounts = e.target.value;
    setFeatureData({ ...featureData, allowedAccounts });
    clearMsg();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    props.startUpdateFeatureList(featureData);
  };

  return (
    <Container className="mt-3">
      <h5 className="mb-3">App Features</h5>
      {_.isEmpty(featureList) ? (
        <div>Still Loading</div>
      ) : (
        <Row className="mt-2">
          <Col sm="3">
            <FormGroup check>
              <Label check className="mb-3 customcheck" htmlFor="allowSignUp">
                <Input
                  type="checkbox"
                  id="allowSignUp"
                  checked={featureData.allowSignUp ? true : false}
                  onChange={onAllowSignUpChanged}
                />
                <span className="checkmark"></span>
                Allow New User Sign-Up
              </Label>
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup check>
              <Label check className="mb-3 customcheck" htmlFor="allowSignIn">
                <Input
                  type="checkbox"
                  id="allowSignIn"
                  checked={featureData.allowLogin ? true : false}
                  onChange={onallowLoginChanged}
                />
                <span className="checkmark"></span>
                Allow New User Sign-In
              </Label>
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup check>
              <Label check className="mb-3 customcheck" htmlFor="allowExport">
                <Input
                  type="checkbox"
                  id="allowExport"
                  checked={featureData.allowExport ? true : false}
                  onChange={onAllowExportChanged}
                />
                <span className="checkmark"></span>
                Allow Export
              </Label>
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup check>
              <Label check className="mb-3 customcheck" htmlFor="allowImport">
                <Input
                  type="checkbox"
                  id="allowImport"
                  checked={featureData.allowImport ? true : false}
                  onChange={onAllowImportChanged}
                />
                <span className="checkmark"></span>
                Allow Import
              </Label>
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup>
              <Label> Number of Budgets Allowed</Label>
              <Input
                type="text"
                value={featureData.allowedBudgets}
                onChange={onAllowedBudgetsChanged}
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup>
              <Label> Number of Accounts Allowed</Label>
              <Input
                type="text"
                value={featureData.allowedAccounts}
                onChange={onAllowedAccountsChanged}
              />
            </FormGroup>
          </Col>
          <Col sm="2" className="mt-2">
            <Button className="mt-3" color="primary" onClick={onSubmit}>
              Update
            </Button>
          </Col>
        </Row>
      )}
      {msg && <Alert color="warning">{msg}</Alert>}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    featureList: state.appSettings.featureList,
    msg: state.appSettings.featuremsg,
    userInfo: state.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startUpdateFeatureList: (featureData) =>
      dispatch(startUpdateFeatureList(featureData)),
    setFeatureMsg: (msg) => dispatch(setFeatureMsg(msg)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Features);
