import {
  SET_PAYMENT_HISTORY,
  SET_FILTERED_PAYMENT_HISTORY,
} from "./paymentHistoryTypes";

const initialState = {};

const paymentHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_HISTORY:
      return { ...state, allPaymentHistory: action.paymentHistory };
    case SET_FILTERED_PAYMENT_HISTORY:
      return {
        ...state,
        filteredPaymentHistory: action.filteredPaymentHistory,
      };
    default:
      return state;
  }
};

export default paymentHistoryReducer;
