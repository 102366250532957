export const [LOGIN] = ["LOGIN"];

//views

export const [
  DASHBOARD,
  PAYMENTS,
  USERS,
  APP_SETTINGS,
  REPORTS,
  FEATURES,
  DELUSERS,
  REF_SETTINGS,
] = [
  "DASHBORAD",
  "PAYMENTS",
  "USERS",
  "APP_SETTINGS",
  "REPORTS",
  "FEATURES",
  "DELUSERS",
  "REF_SETTINGS",
];
