import { SET_USER_STAT, SET_PAYMENT_STAT } from "./dashboardTypes";

const initialState = {
  userStat: {
    totalUsers: 0,
    totalActiveUsers: 0,
    totalActiveSubscribedUsers: 0,
    totalActiveTrialUsers: 0,
    totalDisabledUsers: 0,
    totalExpiredUsers: 0,
    totalDeletedUsers: 0,
  },
  paymentStat: {
    totalPayment: 0,
    thisMonthPayment: 0,
    lastMonthPayment: 0,
  },
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_STAT:
      return { ...state, userStat: action.userStat };
    case SET_PAYMENT_STAT:
      return { ...state, paymentStat: action.paymentStat };
    default:
      return state;
  }
};

export default dashboardReducer;
