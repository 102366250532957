import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { validAmount } from "../../validation/amount";
import {
  updateUserRefundDetails,
  //updateUserDetails,
} from "../../redux/users/usersActions";
import { Row, Col, Button, Input, Label } from "reactstrap";

const Refund = (props) => {
  const { customerInContext, userInfo } = props;
  const [userDetailsToUpdate, setUserDetailsToUpdate] = useState(
    customerInContext ? customerInContext : {}
  ); //main update

  useEffect(() => {
    if (!_.isEmpty(customerInContext)) {
      const lastPaymentAmt = customerInContext.subscriptionAmount / 100;
      const a = moment(customerInContext.subscriptionStartDate);
      const b = moment(customerInContext.dateAccountDeleted);
      const dayDiff = b.diff(a, "days");
      if (customerInContext.subscriptionFrequency === "monthly") {
        const temp = (lastPaymentAmt / 30) * dayDiff;
        setUserDetailsToUpdate({
          ...customerInContext,
          refundAmount: (lastPaymentAmt - temp).toFixed(2),
        });
      }
      if (customerInContext.subscriptionFrequency === "yearly") {
        const temp1 = (lastPaymentAmt / 365) * dayDiff;
        setUserDetailsToUpdate({
          ...customerInContext,
          refundAmount: (lastPaymentAmt - temp1).toFixed(2),
        });
      }
    }
  }, [customerInContext]);

  const refundAmtChanged = (e) => {
    const temp = e.target.value;
    if (validAmount(temp)) {
      setUserDetailsToUpdate({ ...userDetailsToUpdate, refundAmount: temp });
    }
  };

  const refundReasonChanged = (e) => {
    const temp = e.target.value;
    setUserDetailsToUpdate({ ...userDetailsToUpdate, refundReason: temp });
  };

  const onRefund = () => {
    const updatedUser = {
      ...userDetailsToUpdate,
      refundAmount: userDetailsToUpdate.refundAmount * 100,
      refundDate: new Date().toISOString(),
      refunded: true,
      lastModifiedDate: new Date().toISOString(),
      lastModifiedBy: userInfo.email + "-" + userInfo.role,
    };
    setUserDetailsToUpdate(updatedUser);
    //console.log("user update", updatedUser);
    props.updateUserRefundDetails(updatedUser, customerInContext);
  };

  return (
    <Row className="mt-2">
      <Col sm="2">
        <Label>Amount to Refund ($)</Label>
        <Input
          type="text"
          id="refund"
          placeholder="0"
          value={userDetailsToUpdate ? userDetailsToUpdate.refundAmount : ""}
          onChange={refundAmtChanged}
          disabled={
            customerInContext && customerInContext.refunded === true
              ? true
              : false
          }
        />
      </Col>
      <Col sm="5">
        <Label>Reason For Refund</Label>
        <Input
          type="text"
          id="refundReason"
          placeholder="Enter the Reason"
          value={userDetailsToUpdate ? userDetailsToUpdate.refundReason : ""}
          onChange={refundReasonChanged}
          disabled={
            customerInContext && customerInContext.refunded === true
              ? true
              : false
          }
        />
      </Col>
      <Col className="mt-2">
        {customerInContext && customerInContext.refunded === true ? (
          <Button disabled className="mt-3">
            Already Refunded
          </Button>
        ) : (
          <Button color="primary" onClick={onRefund} className="mt-3">
            Process Refund
          </Button>
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    customerInContext: state.users.customerInContext,
    userInfo: state.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserRefundDetails: (userDetailsToUpdate, userDetails) =>
      dispatch(updateUserRefundDetails(userDetailsToUpdate, userDetails)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Refund);
