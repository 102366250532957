import {
  SET_CURRENT_PRICE,
  SET_PRICING_HISTORY,
  UPDATE_PRICING_HISTORY,
  PRICE_MSG,
  SET_TRIAL_PERIOD,
  SET_TRIAL_HISTORY,
  UPDATE_TRIAL_HISTORY,
  TRIAL_MSG,
  SET_FEATURE_LIST,
  SET_FEATURE_HISTORY,
  UPDATE_FEATURE_HISTORY,
  FEATURE_MSG,
  APP_CONFIG_MSG,
} from "./appSettingsTypes";
import database from "../../firebase/firebase";
import firebase from "firebase";

export const setCurrentPrice = (currentPricing) => {
  return {
    type: SET_CURRENT_PRICE,
    currentPricing,
  };
};

export const setPricingHistory = (pricingHistory) => {
  return {
    type: SET_PRICING_HISTORY,
    pricingHistory,
  };
};

export const updatePricingHistory = (pricingData) => {
  return {
    type: UPDATE_PRICING_HISTORY,
    pricingData,
  };
};

export const setPriceMsg = (pricemsg) => {
  return {
    type: PRICE_MSG,
    pricemsg,
  };
};

export const startGetAppSettings = () => {
  return (dispatch) => {
    return database
      .collection(`adminInfo/`)
      .doc("appSettings")
      .get()
      .then((snapshot) => {
        const pricingHistory = snapshot.data().pricingHistory;
        const currentPricing = snapshot.data().currentPricing;
        const trialPeriod = snapshot.data().trialPeriod;
        const trialHistory = snapshot.data().trialHistory;
        const featureList = snapshot.data().features;
        const featureHistory = snapshot.data().featureHistory;
        dispatch(setPricingHistory(pricingHistory));
        dispatch(setCurrentPrice(currentPricing));
        dispatch(setTrialPeriod(trialPeriod));
        dispatch(setTrialHistory(trialHistory));
        dispatch(setFeatureList(featureList));
        dispatch(setFeatureHistory(featureHistory));
      })
      .catch((err) => {
        console.log("Error Retriving Pricing Details", err.message);
      });
  };
};

export const startUpdateAppPricing = (pricingData) => {
  return (dispatch) => {
    return database
      .collection("adminInfo")
      .doc("appSettings")
      .update({
        //Checking: firebase.firestore.FieldValue.arrayUnion(4),
        pricingHistory: firebase.firestore.FieldValue.arrayUnion(pricingData),
        currentPricing: {
          monthly: {
            price: pricingData.newMonthlyPrice,
            priceID: Math.floor(Date.now()).toString(),
          },
          yearly: {
            price: pricingData.newYearlyPrice,
            priceID: Math.floor(2 * Date.now()).toString(),
          },
          tax: pricingData.newTax,
        },
      })
      .then(() => {
        dispatch(
          setCurrentPrice({
            monthly: {
              price: pricingData.newMonthlyPrice,
              priceID: Math.floor(Date.now()).toString(),
            },
            yearly: {
              price: pricingData.newYearlyPrice,
              priceID: Math.floor(2 * Date.now()).toString(),
            },
            tax: pricingData.newTax,
          })
        );
        dispatch(updatePricingHistory(pricingData));
        dispatch(setPriceMsg("Successfully Updated the Pricing"));
      })
      .catch((err) =>
        dispatch(setPriceMsg(`Issue updating the price - ${err.message}`))
      );
  };
};

//trialPeriodData;

export const setTrialPeriod = (trialPeriod) => {
  return {
    type: SET_TRIAL_PERIOD,
    trialPeriod,
  };
};

export const setTrialHistory = (trialHistory) => {
  return {
    type: SET_TRIAL_HISTORY,
    trialHistory,
  };
};

export const updateTrialHistory = (trialHistory) => {
  return {
    type: UPDATE_TRIAL_HISTORY,
    trialHistory,
  };
};

export const setTrialMsg = (trialmsg) => {
  return {
    type: TRIAL_MSG,
    trialmsg,
  };
};

export const startUpdateTrialPeriod = (trialPeriodData) => {
  return (dispatch) => {
    return database
      .collection("adminInfo")
      .doc("appSettings")
      .update(
        {
          //Checking: firebase.firestore.FieldValue.arrayUnion(4),
          trialHistory:
            firebase.firestore.FieldValue.arrayUnion(trialPeriodData),
          trialPeriod: trialPeriodData.newTrialPeriod,
        },
        { merge: true }
      )
      .then(() => {
        dispatch(setTrialPeriod(trialPeriodData.newTrialPeriod));
        dispatch(updateTrialHistory(trialPeriodData));
        dispatch(setTrialMsg("Successfully Updated the Trial Period"));
      })
      .catch((err) =>
        dispatch(
          setTrialMsg(`Issue updating the trial period - ${err.message}`)
        )
      );
  };
};

//Features

export const setFeatureList = (featureList) => {
  return {
    type: SET_FEATURE_LIST,
    featureList,
  };
};

export const setFeatureHistory = (featureHistory) => {
  return {
    type: SET_FEATURE_HISTORY,
    featureHistory,
  };
};

export const updateFeatureHistory = (featureHistory) => {
  return {
    type: UPDATE_FEATURE_HISTORY,
    featureHistory,
  };
};

export const setFeatureMsg = (featuremsg) => {
  return {
    type: FEATURE_MSG,
    featuremsg,
  };
};

export const startUpdateFeatureList = (featureList) => {
  //console.log("..", featureList);
  return (dispatch) => {
    return database
      .collection("adminInfo")
      .doc("appSettings")
      .update({
        features: featureList,
        featureHistory: firebase.firestore.FieldValue.arrayUnion(featureList),
      })
      .then(() => {
        dispatch(setFeatureList(featureList));
        dispatch(updateFeatureHistory(featureList));
        dispatch(setFeatureMsg("Successfully Updated the Features"));
      })
      .catch((err) =>
        dispatch(setFeatureMsg(`Issue updating the Feature - ${err.message}`))
      );
  };
};

//Default Onetime categories, subcategories, dateFormat

export const setAppConfigMsg = (appConfigMsg) => {
  return {
    type: APP_CONFIG_MSG,
    appConfigMsg,
  };
};

export const setDefaultCategories = (categories, subcategories) => {
  return (dispatch) => {
    return database
      .collection("configs")
      .doc("appConfigs")
      .update({ categories, subcategories })
      .then(() => {
        dispatch(
          setAppConfigMsg(
            "Successfully Updated Default Categories and Subcategories"
          )
        );
      })
      .catch((err) => {
        dispatch(
          setAppConfigMsg(
            `Issue Updating the Default Categories and Subcategories -${err.message}`
          )
        );
      });
  };
};

// export const setDefaultSubcategories = (subcategories) => {
//   return (dispatch) => {
//     return database
//       .collection("configs")
//       .doc("appConfigs")
//       .update({ subcategories })
//       .then(() => {
//         dispatch(setAppConfigMsg("Successfully Updated Default Subcategories"));
//       })
//       .catch((err) => {
//         dispatch(
//           setAppConfigMsg(
//             `Issue Updating the Default Subcategories -${err.message}`
//           )
//         );
//       });
//   };
// };

export const setDefaultAppCategories = (appCategories, appSubcategories) => {
  return (dispatch) => {
    return database
      .collection("configs")
      .doc("appConfigs")
      .update({ appCategories, appSubcategories })
      .then(() => {
        dispatch(
          setAppConfigMsg(
            "Successfully Updated Default App Categories and App Subcategories"
          )
        );
      })
      .catch((err) => {
        dispatch(
          setAppConfigMsg(
            `Issue Updating the Default App Categories and App Subcategories -${err.message}`
          )
        );
      });
  };
};

// export const setDefaultAppSubcategories = (appSubcategories) => {
//   return (dispatch) => {
//     return database
//       .collection("configs")
//       .doc("appConfigs")
//       .update({ appSubcategories })
//       .then(() => {
//         dispatch(
//           setAppConfigMsg("Successfully Updated Default App Subcategories")
//         );
//       })
//       .catch((err) => {
//         dispatch(
//           setAppConfigMsg(
//             `Issue Updating the Default App Subcategories -${err.message}`
//           )
//         );
//       });
//   };
// };

export const setDefaultPayees = (appPayees) => {
  return (dispatch) => {
    return database
      .collection("configs")
      .doc("appConfigs")
      .update({ appPayees })
      .then(() => {
        dispatch(setAppConfigMsg("Successfully Updated Default App Payees"));
      })
      .catch((err) => {
        dispatch(
          setAppConfigMsg(
            `Issue Updating the Default App Payees -${err.message}`
          )
        );
      });
  };
};

export const setDateFormat = (dateFormatForBudget, dateFormatForImport) => {
  return (dispatch) => {
    return database
      .collection("configs")
      .doc("appConfigs")
      .update({ dateFormatForBudget, dateFormatForImport })
      .then(() => {
        dispatch(setAppConfigMsg("Successfully Updated Date Format"));
      })
      .catch((err) => {
        dispatch(
          setAppConfigMsg(`Issue Updating the Date Format -${err.message}`)
        );
      });
  };
};

export const setCurrencies = (currencies) => {
  return (dispatch) => {
    return database
      .collection("configs")
      .doc("appConfigs")
      .update({ currencies })
      .then(() => {
        dispatch(setAppConfigMsg("Successfully Updated Currencies"));
      })
      .catch((err) => {
        dispatch(
          setAppConfigMsg(`Issue Updating the Currencies -${err.message}`)
        );
      });
  };
};

export const setAccountTypes = (accountTypes) => {
  return (dispatch) => {
    return database
      .collection("configs")
      .doc("appConfigs")
      .update({ accountTypes })
      .then(() => {
        dispatch(setAppConfigMsg("Successfully Updated Account Types"));
      })
      .catch((err) => {
        dispatch(
          setAppConfigMsg(`Issue Updating the Account Types -${err.message}`)
        );
      });
  };
};

export const setCountries = (countries) => {
  return (dispatch) => {
    return database
      .collection("configs")
      .doc("staticData")
      .update({ countries })
      .then(() => {
        dispatch(setAppConfigMsg("Successfully Updated Countries"));
      })
      .catch((err) => {
        dispatch(
          setAppConfigMsg(`Issue Updating the Countries -${err.message}`)
        );
      });
  };
};

export const setUSStates = (usStates) => {
  return (dispatch) => {
    return database
      .collection("configs")
      .doc("staticData")
      .update({ usStates })
      .then(() => {
        dispatch(setAppConfigMsg("Successfully Updated States of USA"));
      })
      .catch((err) => {
        dispatch(
          setAppConfigMsg(`Issue Updating the States of USA -${err.message}`)
        );
      });
  };
};

export const setCanadaStates = (canadaStates) => {
  return (dispatch) => {
    return database
      .collection("configs")
      .doc("staticData")
      .update({ canadaStates })
      .then(() => {
        dispatch(setAppConfigMsg("Successfully Updated States of Canada"));
      })
      .catch((err) => {
        dispatch(
          setAppConfigMsg(`Issue Updating the States of Canada -${err.message}`)
        );
      });
  };
};

export const setIndianStates = (indianStates) => {
  return (dispatch) => {
    return database
      .collection("configs")
      .doc("staticData")
      .update({ indianStates })
      .then(() => {
        dispatch(setAppConfigMsg("Successfully Updated States of India"));
      })
      .catch((err) => {
        dispatch(
          setAppConfigMsg(`Issue Updating the States of India -${err.message}`)
        );
      });
  };
};

export const setCancelReasons = (reasons) => {
  return (dispatch) => {
    return database
      .collection("configs")
      .doc("appConfigs")
      .update({ cancelReasons: reasons })
      .then(() => {
        dispatch(
          setAppConfigMsg("Successfully Updated reasons for cancelling")
        );
      })
      .catch((err) => {
        dispatch(
          setAppConfigMsg(
            `Issue Updating the reasons for cancelling -${err.message}`
          )
        );
      });
  };
};

export const setMessages = (messages) => {
  return (dispatch) => {
    return database
      .collection("configs")
      .doc("messages")
      .update(messages)
      .then(() => {
        dispatch(setAppConfigMsg("Successfully Updated Static Messages"));
      })
      .catch((err) => {
        dispatch(
          setAppConfigMsg(`Issue Updating the Static Messages -${err.message}`)
        );
      });
  };
};

export const setReservedKeyWord = (keywords) => {
  return (dispatch) => {
    return database
      .collection("configs")
      .doc("appConfigs")
      .update({ keywords })
      .then(() => {
        dispatch(setAppConfigMsg("Successfully Updated Reserved Keywords"));
      })
      .catch((err) => {
        dispatch(
          setAppConfigMsg(
            `Issue Updating the Reserved Keywords -${err.message}`
          )
        );
      });
  };
};

export const setDefaultConfigs = (
  categories,
  subcategories,
  appCategories,
  appSubcategories,
  appPayees,
  dateFormat,
  currencies,
  accountTypes
) => {
  return (dispatch) => {
    return database
      .collection("configs")
      .doc("appConfigs")
      .set({
        categories,
        subcategories,
        appCategories,
        appSubcategories,
        appPayees,
        dateFormat,
        currencies,
        accountTypes,
      })

      .then(() => {
        dispatch(
          setAppConfigMsg("Successfully setting the App Default Config")
        );
      })
      .catch((err) => {
        dispatch(
          setAppConfigMsg(
            `Issue setting the App Default Config -${err.message}`
          )
        );
      });
  };
};
