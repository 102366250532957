import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import M from "materialize-css";
import { updateUserDetails, setMsg } from "../../redux/users/usersActions";
import _ from "lodash";

import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
} from "reactstrap";

function UserInfoEdit(props) {
  useEffect(() => {
    M.AutoInit();
  }, []);

  const { userDetails } = props;

  const { userInfo, msg } = props;
  const [update, setUpdate] = useState(false);
  //const [showRefund, setShowRefund] = useState(false);

  const [isEnabled, setIsEnabled] = useState();
  const [role, setRole] = useState();
  const [emailSubscription, setEmailSubscription] = useState();
  //const [autoRenew, setAutoRenew] = useState();

  useEffect(() => {
    if (!_.isEmpty(userDetails)) {
      setIsEnabled(userDetails.isEnabled);
      setRole(userDetails.role);
      setEmailSubscription(userDetails.emailSubscription);
      //setAutoRenew(userDetails.autoRenew);
    }
  }, [userDetails]);

  /*useEffect(() => {
    if (!_.isEmpty(userDetails)) {
      if (
        userDetails?.accountStatus === "deleted" &&
        customerInContext?.refunded
      ) {
        setShowRefund(true);
      }
    }
  }, [userDetails]);*/

  useEffect(() => {
    emailSubscription !== userDetails.emailSubscription ||
    isEnabled !== userDetails.isEnabled ||
    role !== userDetails.role
      ? setUpdate(true)
      : setUpdate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled, role, emailSubscription]);

  const emailSubscriptionChanged = (e) => {
    props.setMsg();
    setEmailSubscription(!emailSubscription);
  };

  const accountStatusChanged = (e) => {
    props.setMsg();
    setIsEnabled(!isEnabled);
  };

  const onRoleChange = (e) => {
    props.setMsg();
    setRole(e.target.value);
  };

  const onUpdate = (e) => {
    e.preventDefault();
    if (update) {
      props.updateUserDetails(
        {
          ...userDetails,
          isEnabled,
          emailSubscription,
          lastModifiedDate: new Date().toISOString(),
          lastModifiedBy: userInfo.email + "-" + userInfo.role,
        },
        userDetails
      );
    }
  };

  return (
    <Fragment>
      <Container className="border border-primary mt-2 p-2">
        <Form inline>
          <h6>
            User ID:<strong>{userDetails.id}</strong>
          </h6>
          <hr />
          <h6>Account Enabled:</h6>
          <FormGroup check>
            <Label
              check
              className="ml-2 mb-3 customcheck"
              htmlFor="accountEnable"
            >
              <Input
                type="checkbox"
                id="accountEnable"
                onChange={accountStatusChanged}
                checked={isEnabled ? isEnabled : false}
              />
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
          <hr />
          <h6>Email Subscription:</h6>
          <FormGroup check>
            <Label
              check
              className="ml-2 mb-3 customcheck"
              htmlFor="emailSubscription"
            >
              <Input
                type="checkbox"
                id="emailSubscription"
                onChange={emailSubscriptionChanged}
                checked={emailSubscription ? emailSubscription : false}
              />
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
          <hr />
          <h6 className="mr-2">Role:</h6>
          <Input
            disabled
            value={userDetails ? userDetails.role : ""}
            onChange={onRoleChange}
            style={{ maxWidth: "7rem", height: "2rem" }}
          />
          <hr />
          <Button
            color="primary"
            size="sm"
            disabled={!update}
            onClick={onUpdate}
          >
            Update
          </Button>
        </Form>
        {msg && (
          <Alert className="mt-2" color="info">
            {msg}
          </Alert>
        )}
      </Container>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    msg: state.users.msg,
    userInfo: state.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserDetails: (updatedUserDetails, userDetails) =>
      dispatch(updateUserDetails(updatedUserDetails, userDetails)),
    setMsg: () => dispatch(setMsg("")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoEdit);
