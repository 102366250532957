export const validPercentage = (percentageEntered) => {
  //console.log("Value came in", valueEntered);
  const validPercentage = /^\d{0,2}?(\.\d{0,2})?$/;
  const validLength = 5;
  return !percentageEntered ||
    (percentageEntered.match(validPercentage) &&
      percentageEntered.length <= validLength)
    ? true
    : false;
};
