import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import PaymentHistoryTable from "../payments/PaymentHistoryTable";
import {
  updateUserDetails,
  setMsg,
  getCustomerInfoChangeHistory,
  setCustomerInfoChangeHistory,
} from "../../redux/users/usersActions";
import { setView } from "../../redux/setView/setViewActions";
import CustomerInfoChangeHistory from "./CustomerInfoChangeHistory";
import Refund from "./Refund";
import _ from "lodash";
import { doGetPaymentStat } from "../../redux/dashboard/dashboardActions";
import UserInfoDisplay from "./UserInfoDisplay";
import UserInfoEdit from "./UserInfoEdit";
import { Button, Container, Form } from "reactstrap";

function UsersDetails(props) {
  const initValues = {
    id: "",
    emailSubscription: "",
    isEnabled: "",
  };

  const { customerInContext } = props;
  const [userDetails, setUserDetails] = useState();
  const [userDetailsToUpdate, setUserDetailsToUpdate] = useState(initValues);
  const [showRefund, setShowRefund] = useState(false);
  //const [updatedUserInfo, setUpdatedUserInfo] = useState(); //For History

  useEffect(() => {
    if (!_.isEmpty(customerInContext)) {
      //setUpdatedUserInfo(customerInContext[0]);
      props.setCustomerInfoChangeHistory();
      setUserDetails(customerInContext);
      setUserDetailsToUpdate({
        ...userDetailsToUpdate,
        emailSubscription: customerInContext
          ? customerInContext.emailSubscription
          : "",
        isEnabled: customerInContext ? customerInContext.isEnabled : "",
        id: customerInContext ? customerInContext.id : "",
      });
      if (
        customerInContext.accountStatus === "deleted" &&
        customerInContext.subscriptionStatus === "subscribed" &&
        customerInContext.refunded !== true
      ) {
        setShowRefund(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInContext]);

  const refreshUserHistory = () => {
    props.getCustomerInfoChangeHistory(customerInContext.id);
  };

  const refreshPaymentHistory = () => {
    props.doGetPaymentStat();
  };

  const goBackClicked = () => {
    props.setView("USERS");
  };

  return (
    <Fragment>
      <Container>
        <Button
          className="mt-2"
          color="secondary"
          size="sm"
          onClick={goBackClicked}
        >
          Go Back
        </Button>
        {showRefund && <Refund />}
      </Container>
      {userDetails ? (
        <div>
          <Container className="border border-primary mt-2 p-2">
            <Form inline>
              <h6>
                Email:<strong>{userDetails.email}</strong>
              </h6>
              <hr />
              <h6>
                Subscription Status:
                <strong>{userDetails.subscriptionStatus.toUpperCase()}</strong>
              </h6>
              <hr />
              {userDetails.accountStatus === "deleted" && (
                <h6>
                  Refund Status:
                  <strong>
                    {userDetails.refunded === true
                      ? "Refunded"
                      : "Not Refunded"}
                  </strong>
                </h6>
              )}
            </Form>
          </Container>
          <UserInfoEdit userDetails={userDetails} />
          <UserInfoDisplay userDetails={userDetails} />
          <Container className="my-3">
            <h6>
              User Info Change History
              <Button color="primary" outline size="sm" className="ml-2">
                <i
                  className="material-icons pointer"
                  onClick={refreshUserHistory}
                >
                  refresh
                </i>
              </Button>
            </h6>
            <CustomerInfoChangeHistory />
          </Container>

          <Container className="my-3">
            <h6>
              User Payment History
              <Button color="primary" outline size="sm" className="ml-2">
                <i
                  className="material-icons pointer"
                  onClick={refreshPaymentHistory}
                >
                  refresh
                </i>
              </Button>
            </h6>
            <PaymentHistoryTable email={customerInContext.email} />
          </Container>
        </div>
      ) : (
        <div>No User Data to Display</div>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    customerInContext: state.users.customerInContext,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserDetails: (dataToUpdate) =>
      dispatch(updateUserDetails(dataToUpdate)),
    setView: (view) => dispatch(setView(view)),
    setMsg: () => dispatch(setMsg("")),
    getCustomerInfoChangeHistory: (cid) =>
      dispatch(getCustomerInfoChangeHistory(cid)),
    setCustomerInfoChangeHistory: () =>
      dispatch(setCustomerInfoChangeHistory([])),
    doGetPaymentStat: () => dispatch(doGetPaymentStat()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersDetails);
