import React from "react";

const LoadingPage = () => (
  <div className="container center-align">
    <img
      className="loader__image"
      src="/images/loader.gif"
      alt="Page Loading..."
    />
  </div>
);

export default LoadingPage;
