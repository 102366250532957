import {
  SET_PAYMENT_HISTORY,
  SET_FILTERED_PAYMENT_HISTORY,
} from "./paymentHistoryTypes";
import database from "../../firebase/firebase";

export const setPaymentHistory = (paymentHistory) => {
  return {
    type: SET_PAYMENT_HISTORY,
    paymentHistory,
  };
};

export const setFilteredPaymentHistory = (filteredPaymentHistory) => {
  return {
    type: SET_FILTERED_PAYMENT_HISTORY,
    filteredPaymentHistory,
  };
};

export const setPaymentSummary = (paymentSummary) => {
  return (dispatch, getState) => {
    if (paymentSummary) {
      dispatch(setPaymentHistory(paymentSummary));
      dispatch(setFilteredPaymentHistory(paymentSummary));
    }
  };
};

export const searchPaymentHistoryByEmail = (email) => {
  //console.log("Call came here....", email);
  return (dispatch, getState) => {
    const paymentHistory = getState().paymentHistory.allPaymentHistory;
    const filteredPaymentHistory =
      paymentHistory &&
      paymentHistory.filter((payment) => payment.userEmail === email);
    dispatch(setFilteredPaymentHistory(filteredPaymentHistory));
  };
};

export const searchPaymentHistoryByDates = (startDate, endDate) => {
  return (dispatch, getState) => {
    return database
      .collection(`adminInfo/paymentInfo/paymentHistory`)
      .where("paymentDate", ">=", startDate)
      .where("paymentDate", "<=", endDate)
      .orderBy("paymentDate", "desc")
      .get()
      .then((snapshot) => {
        const paymentHistory = [];
        snapshot.forEach((childSnapshot) => {
          paymentHistory.push({
            paymentID: childSnapshot.id,
            ...childSnapshot.data(),
          });
        });
        dispatch(setPaymentHistory(paymentHistory));
      })
      .catch((err) => {
        console.log("Error Retriving Payment History", err.message);
      });
  };
};

// //update the userInfo of email verified after checking if changed
// export const startUpdateUserInfoEV = (emailVerified, email) => {
//   return (dispatch, getState) => {
//     const uid = getState().auth.uid;
//     return database
//       .collection(`usersInfo`)
//       .doc(`${uid}`)
//       .update({ emailVerified: emailVerified, email: email })
//       .then(() => {
//         dispatch(startGetUserInfo());
//       })
//       .catch((err) => {
//         console.log("Error", err.message);
//       });
//   };
// };
