import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  //AgGridColumn,
  AgGridReact,
} from "ag-grid-react";
import moment from "moment";
import numFormat from "../../selectors/numFormat";
import sortByDate from "../../selectors/sortByDate";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

const CustomerInfoChangeHistory = (props) => {
  const { customerInfoChangeHistory } = props;

  // const [gridApi, setGridApi] = useState(null);
  // const [gridColumnApi, setGridColumnApi] = useState(null);

  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([]);

  function onGridReady(params) {
    // setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
  }

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Date Created",
        field: "date",
        resizable: true,
      },
      { headerName: "User ID", field: "userID", resizable: true },
      {
        headerName: "Email",
        field: "email",
        resizable: true,
      },
      {
        headerName: "Subscription Status",
        field: "subscriptionStatus",
        resizable: true,
      },
      { headerName: "Auto Renew", field: "autoRenew", resizable: true },
      { headerName: "Is Enabled", field: "isEnabled", resizable: true },
      { headerName: "First Name", field: "firstName", resizable: true },
      { headerName: "Last Name", field: "lastName", resizable: true },
      { headerName: "City", field: "city", resizable: true },
      { headerName: "State", field: "state", resizable: true },
      { headerName: "Country", field: "country", resizable: true },
      { headerName: "Role", field: "role", resizable: true },
      {
        headerName: "emailSubscribed",
        field: "emailSubscription",
        resizable: true,
      },
      { headerName: "Subscribed On", field: "subscribedOn", resizable: true },
      {
        headerName: "Subscription Amount",
        field: "subscriptionAmount",
        resizable: true,
      },
      {
        headerName: "Subscription Frequency",
        field: "subscriptionFrequency",
        resizable: true,
      },
      {
        headerName: "Subscription Start Date",
        field: "subscriptionStartDate",
        resizable: true,
      },
      {
        headerName: "Subscription End Date",
        field: "subscriptionEndDate",
        resizable: true,
      },
      {
        headerName: "T&C Accepted On",
        field: "dateAcceptedTandC",
        resizable: true,
      },
      {
        headerName: "Trial Start Date",
        field: "trialStartDate",
        resizable: true,
      },
      { headerName: "Trial End Date", field: "trialEndDate", resizable: true },
      { headerName: "Refunded", field: "refunded", resizable: true },
      { headerName: "Refund Date", field: "refundDate", resizable: true },
      { headerName: "Refund Amount", field: "refundAmount", resizable: true },
      { headerName: "Refund Reason", field: "refundReason", resizable: true },

      {
        headerName: "Account Delete On",
        field: "dateAccountDeleted",
        resizable: true,
      },
      {
        headerName: "Account Disabled On",
        field: "dateAccountDisabled",
        resizable: true,
      },
      {
        headerName: "Last Modified By",
        field: "lastModifiedBy",
        resizable: true,
      },
      {
        headerName: "Last Modified On",
        field: "lastModifiedOn",
        resizable: true,
      },
    ]);
  }, []);

  useEffect(() => {
    const temp =
      customerInfoChangeHistory.length > 0
        ? customerInfoChangeHistory.map((history) => ({
            date: moment(history.date).format(),
            userID: history.userID,
            email: history.email,
            subscriptionStatus: history.subscriptionStatus,
            autoRenew: history.autoRenew,
            isEnabled: history.isEnabled ? "Yes" : "No",
            firstName: history.firstName,
            lastName: history.lastName,
            city: history.city,
            state: history.state,
            country: history.country,
            role: history.role,
            emailSubscription: history.emailSubscription ? "Yes" : "No",
            subscribedOn: history.subscribedOn
              ? moment(history.subscribedOn).format()
              : "-",
            subscriptionAmount: numFormat(history.subscriptionAmount),
            subscriptionFrequency: history.subscriptionFrequency,
            subscriptionStartDate: history.subscriptionStartDate
              ? moment(history.subscriptionStartDate).format()
              : "-",
            subscriptionEndDate: history.subscriptionEndDate
              ? moment(history.subscriptionEndDate).format()
              : "-",
            dateAcceptedTandC: history.dateAcceptedTandC
              ? moment(history.dateAcceptedTandC).format()
              : "-",
            trialStartDate: history.trialStartDate
              ? moment(history.trialStartDate).format()
              : "-",
            trialEndDate: history.trialEndDate
              ? moment(history.trialEndDate).format()
              : "-",
            refunded: history.refunded,
            refundDate: history.refundDate
              ? moment(history.refundDate).format()
              : "-",
            refundAmount: numFormat(history.refundAmount),
            refundReason: history.refundReason,
            dateAccountDeleted: history.dateAccountDeleted
              ? moment(history.dateAccountDeleted).format()
              : "-",
            dateAccountDisabled: history.dateAccountDisabled
              ? moment(history.dateAccountDisabled).format()
              : "-",
            lastModifiedBy: history.lastModifiedBy,
            lastModifiedOn: history.lastModifiedOn,
          }))
        : "";
    setRowData(temp);
  }, [customerInfoChangeHistory]);

  return (
    <center>
      <div
        className="ag-theme-balham left-align"
        style={{ height: 400, width: "100%", boxSizing: "border-box" }}
      >
        <AgGridReact
          onGridReady={onGridReady}
          columnDefs={columnDefs}
          rowData={rowData}
          enableFilter={true}
          enableSorting={true}
          pagination="true"
          paginationPageSize="10"
          //floatingFilter="true"
        ></AgGridReact>
      </div>
    </center>
  );
};

const mapStateToProps = (state) => {
  return {
    customerInfoChangeHistory: sortByDate(state.users.history),
  };
};

export default connect(mapStateToProps)(CustomerInfoChangeHistory);
