import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  //AgGridColumn,
  AgGridReact,
} from "ag-grid-react";
import {
  searchUserByEmail,
  setCustomerInContext,
} from "../../redux/users/usersActions";
import { showCustomerDetails } from "../../redux/setView/setViewActions";
import { searchPaymentHistoryByEmail } from "../../redux/paymentHistory/paymentHistoryActions";
import _ from "lodash";
import moment from "moment";

import "ag-grid-community/dist/styles/ag-grid.css";
//import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

const UsersList = (props) => {
  const { role } = props;
  const { rows } = props;

  const [gridApi, setGridApi] = useState(null);
  //const [gridColumnApi, setGridColumnApi] = useState(null);

  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([]);

  function onGridReady(params) {
    setGridApi(params.api);
    //setGridColumnApi(params.columnApi);
  }

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "User ID",
        field: "userID",
        resizable: true,
        width: 240,
      },
      {
        headerName: "Email",
        field: "email",
        resizable: true,
        width: 150,
      },
      {
        headerName: "First Name",
        field: "firstName",
        resizable: true,
        width: 120,
      },
      {
        headerName: "Last Name",
        field: "lastName",
        resizable: true,
        width: 120,
      },
      {
        headerName: "Account Status",
        field: "accountStatus",
        resizable: true,
        width: 120,
      },
      {
        headerName: "Subscription Status",
        field: "subscriptionStatus",
        resizable: true,
        width: 120,
      },
      {
        headerName: "Auto Renew",
        field: "autoRenew",
        resizable: true,
        width: 100,
      },
      {
        headerName: "Subscription End Data",
        field: "subscriptionEndDate",
        resizable: true,
        width: 150,
      },
      {
        headerName: "Trial End Data",
        field: "trialEndDate",
        resizable: true,
        width: 150,
      },
    ]);
  }, []);

  useEffect(() => {
    const temp =
      !_.isEmpty(rows) &&
      rows.map((userListItem) => ({
        userID: userListItem.id,
        email: role === "superAdmin" ? userListItem.email : "--",
        firstName: userListItem.firstName,
        lastName: userListItem.lastName,
        accountStatus: userListItem.accountStatus,
        subscriptionStatus: userListItem.subscriptionStatus,
        autoRenew: userListItem.autoRenew,
        subscriptionEndDate:
          userListItem.subscriptionEndDate &&
          moment(userListItem.subscriptionEndDate).format("DD-MMM-YYYY"),
        trialEndDate:
          userListItem.trialEndDate &&
          moment(userListItem.trialEndDate).format("DD-MMM-YYYY"),
      }));
    setRowData(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const onUserSelected = () => {
    const userSelected = gridApi.getSelectedRows();
    if (!_.isEmpty(userSelected)) {
      const uid = userSelected[0].userID;
      const userToSet = rows.find((row) => row.id === uid);
      props.setCustomerInContext(userToSet);
      props.showCustomerDetails(true);
    }
  };

  return (
    <div
      className="ag-theme-balham left-align"
      style={{ height: 500, width: "100%", boxSizing: "border-box" }}
      onClick={onUserSelected}
    >
      <AgGridReact
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        rowData={rowData}
        enableFilter={true}
        enableSorting={true}
        pagination="true"
        paginationPageSize="10"
        rowSelection="single"
      ></AgGridReact>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.userInfo.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchUserByEmail: (searchCat) => dispatch(searchUserByEmail(searchCat)),
    showCustomerDetails: (show) => dispatch(showCustomerDetails(show)),
    searchPaymentHistoryByEmail: (email) =>
      dispatch(searchPaymentHistoryByEmail(email)),
    setCustomerInContext: (customerDetails) =>
      dispatch(setCustomerInContext(customerDetails)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
