import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import { auth } from "../../firebase/firebase";
import { loadApp } from "../../redux/auth/authActions";
//import { loadConfig } from "../../redux/config/configActions";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const AuthProvider = (props) => {
  const { children } = props;
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  //console.log("Props", props);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      //   console.log("User in context", user);
      user && props.loadApp(user);
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = { currentUser };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

const mapDispatchToState = (dispatch) => {
  return {
    loadApp: (user) => dispatch(loadApp(user)),
    //loadConfig: () => dispatch(loadConfig()),
  };
};

export default connect(undefined, mapDispatchToState)(AuthProvider);
