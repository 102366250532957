import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { doAddReferral, clearMsg } from "../../redux/referral/referralActions";

const EditReferralItem = (props) => {
  const { refDataToEdit } = props;

  const [refData, setRefData] = useState({});

  useEffect(() => {
    setRefData(refDataToEdit);
  }, [refDataToEdit]);

  const onFormDataChanged = (e) => {
    e.preventDefault();
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setRefData({ ...refData, [fieldName]: fieldValue });
  };

  const onSubmit = () => {
    props.saveRefEdit(refData);
  };

  return (
    <tr>
      <td>
        <Input
          type="text"
          readOnly={true}
          value={refData.companyName ? refData.companyName : ""}
        />
      </td>
      <td>
        <Input
          type="text"
          name="contactName"
          value={refData.contactName ? refData.contactName : ""}
          onChange={onFormDataChanged}
          placeholder="Contact Name"
        />
      </td>
      <td>
        <Input
          type="text"
          name="contactPhone"
          value={refData.contactPhone ? refData.contactPhone : ""}
          onChange={onFormDataChanged}
          placeholder="Contact Number"
        />
      </td>
      <td>
        <Input
          type="email"
          name="contactEmail"
          value={refData.contactEmail ? refData.contactEmail : ""}
          onChange={onFormDataChanged}
          placeholder="Contact Email"
        />
      </td>
      <td>
        {" "}
        <Input
          type="text"
          name="refCode"
          readOnly={true}
          value={refData.refCode ? refData.refCode : ""}
        />
      </td>
      <td>
        <Input
          type="text"
          name="notes"
          value={refData.notes ? refData.notes : ""}
          onChange={onFormDataChanged}
          placeholder="Notes"
        />
      </td>
      <td style={{ width: "6rem" }}>
        <i
          className="bi-sd-card-fill text-primary"
          style={{ fontSize: "1.5rem" }}
          title="Save"
          onClick={onSubmit}
        ></i>
        <i
          className="bi-x-circle ml-2"
          style={{ fontSize: "1.5rem" }}
          title="Cancel"
          onClick={() => props.onCancelEdit()}
        ></i>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    msg: state.referralList.msg,
    referralList: state.referralList.referralList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddReferral: (refData) => dispatch(doAddReferral(refData)),
    clearMsg: () => dispatch(clearMsg()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditReferralItem);
