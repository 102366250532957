import React from "react";
import { connect } from "react-redux";
import { Container, Button } from "reactstrap";
import PaymentHistoryTable from "./PaymentHistoryTable";
import PaymentHistorySearch from "./PaymentHistorySearch";
import { doGetPaymentStat } from "../../redux/dashboard/dashboardActions";

const Payments = (props) => {
  const onRefreshPaymentSection = () => {
    props.doGetPaymentStat();
  };

  return (
    <Container>
      <Button
        color="primary"
        className="my-3 float-right"
        size="sm"
        onClick={onRefreshPaymentSection}
      >
        Refresh
      </Button>
      <PaymentHistorySearch />
      <PaymentHistoryTable />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doGetPaymentStat: () => dispatch(doGetPaymentStat()),
});

export default connect(undefined, mapDispatchToProps)(Payments);
