import React, { Fragment } from "react";
import { connect } from "react-redux";
import numFormat from "../../selectors/numFormat";
import { Row, Col } from "reactstrap";

const PaymentSection = (props) => {
  const { paymentStat } = props;

  return (
    <Fragment>
      <Row>
        <Col sm="2" className="border border-primary shadow m-1 p-2">
          <center>
            Total Payment Till Date:
            <h5 className="mt-2">
              {paymentStat ? numFormat(paymentStat.totalPayment) : 0}
            </h5>
          </center>
        </Col>
        <Col sm="2" className="border border-primary shadow m-1 p-2">
          <center>
            Payment Processed This Month:
            <h5 className="mt-2">
              {paymentStat ? numFormat(paymentStat.thisMonthPayment) : 0}
            </h5>
          </center>
        </Col>
        <Col sm="2" className="border border-primary shadow m-1 p-2">
          <center>
            Payment Processed Last Month:
            <h5 className="mt-2">
              {paymentStat ? numFormat(paymentStat.lastMonthPayment) : 0}
            </h5>
          </center>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    paymentStat: state.dashboard.paymentStat,
  };
};

export default connect(mapStateToProps)(PaymentSection);
