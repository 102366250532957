import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import AppRouter from "./routers/AppRouter"; //{ history }
import LoadingPage from "./components/LoadingPage";
//import Spinner from "./components/Spinner";

import "normalize.css/normalize.css";
import "./styles/styles.scss";

import AuthProvider from "./components/auth/AuthContext";

const jsx = (
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        {/*<Spinner />*/}
        <AppRouter />
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById("root"));
    hasRendered = true;
  }
};

ReactDOM.render(<LoadingPage />, document.getElementById("root"));

renderApp();

// firebase.auth().onAuthStateChanged((user) => {
//   //console.log("Firebase Auth", user);
//   if (user) {
//     store.dispatch(login(user.uid, user.emailVerified));
//     store.dispatch(startUpdateUserInfoEV(user.emailVerified, user.email));
//     renderApp();
//   } else {
//     console.log("Logged Out...");
//     store.dispatch(logout());
//     renderApp();
//   }
// });
