import {
  SET_USERS_LIST,
  SET_CUSTOMER_CONTEXT,
  MSG,
  SET_CUSTOMER_INFO_CHANGE_HISTORY,
  UPDATE_CUSTOMER_INFO_CHANGE_HISTORY,
} from "./usersTypes";

const initialState = {
  usersList: [],
  msg: "",
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS_LIST:
      return { ...state, usersList: action.usersList };
    case SET_CUSTOMER_CONTEXT:
      return { ...state, customerInContext: action.customerDetails };
    case SET_CUSTOMER_INFO_CHANGE_HISTORY:
      return { ...state, history: action.history };
    case UPDATE_CUSTOMER_INFO_CHANGE_HISTORY:
      //console.log("action", action.updatedUserInfo);
      return {
        ...state,
        history: [...state.history, action.updatedUserInfo],
      };
    case MSG:
      return { ...state, msg: action.msg };
    default:
      return state;
  }
};

export default usersReducer;
