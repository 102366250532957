import React from "react";
import { connect } from "react-redux";
import {
  doGetUserStat,
  doGetPaymentStat,
} from "../../redux/dashboard/dashboardActions";
import PaymentSection from "./PaymentSection";
import UsersSection from "./UsersSection";
import { Container, Button } from "reactstrap";

const Dashboard = (props) => {
  const onRefreshUserSection = () => {
    props.doGetUserStat();
  };

  const onRefreshPaymentSection = () => {
    props.doGetPaymentStat();
  };

  return (
    <Container>
      <Button color="primary" className="my-3" onClick={onRefreshUserSection}>
        Refresh
      </Button>
      <UsersSection />
      <hr />
      <Button
        color="primary"
        className="mb-3"
        onClick={onRefreshPaymentSection}
      >
        Refresh
      </Button>
      <PaymentSection />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doGetUserStat: () => dispatch(doGetUserStat()),
  doGetPaymentStat: () => dispatch(doGetPaymentStat()),
});

export default connect(undefined, mapDispatchToProps)(Dashboard);
