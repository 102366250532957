import React, { useState, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { validateEmail } from "../../validation/auth";
import {
  searchPaymentHistoryByEmail,
  searchPaymentHistoryByDates,
} from "../../redux/paymentHistory/paymentHistoryActions";
import { Row, Col, Input, Label, Button, Alert } from "reactstrap";
import DropdownList from "react-widgets/DropdownList";

const PaymentHistorySearch = (props) => {
  const [email, setEmail] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [searchBy, setSearchBy] = useState("Email");
  const [startDate] = useState(new Date());
  const [endDate] = useState(new Date());

  const searchByOptions = ["Email", "Dates"];

  const formRef = useRef();

  const onEmailChange = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onSearchChange = (value) => {
    setErrorMsg("");
    setSearchBy(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("search by", searchBy);
    if (searchBy === "Email") {
      if (validateEmail(email)) {
        searchBy === "Email" && props.searchPaymentHistoryByEmail(email);
        setErrorMsg("");
        formRef.current.reset();
      } else {
        setErrorMsg("Invalid email address!!!");
      }
    } else if (searchBy === "Dates") {
      props.searchPaymentHistoryByDates(startDate, endDate);
    }
  };

  return (
    <Fragment>
      <form className="my-3" onSubmit={onSubmit} ref={formRef}>
        <Row>
          <Col sm="2">
            <Label>Search Option</Label>
            <DropdownList
              className="custom-dropdown"
              data={searchByOptions}
              onChange={onSearchChange}
              value={searchBy}
            />
          </Col>
          {searchBy === "Email" && (
            <Col sm="3">
              <Label htmlFor="email">Email Address</Label>
              <Input type="email" id="email" onChange={onEmailChange} />
            </Col>
          )}
          <Col sm="3" className="mt-2">
            <Button
              color="primary"
              size="sm"
              className="mt-4"
              onChange={onSubmit}
            >
              Search
            </Button>
          </Col>
          <Col>
            {errorMsg && <Alert color="danger">{errorMsg}</Alert>}
            {searchBy === "Dates" && (
              <Alert color="warning" className="mt-2">
                Yet to enable the feature
              </Alert>
            )}
          </Col>
        </Row>
      </form>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchPaymentHistoryByEmail: (email) =>
      dispatch(searchPaymentHistoryByEmail(email)),
    searchPaymentHistoryByDates: (startDate, endDate) =>
      dispatch(searchPaymentHistoryByDates(startDate, endDate)),
  };
};

export default connect(undefined, mapDispatchToProps)(PaymentHistorySearch);
