export const currencies = [
  {
    symbol: "$",
    name: "US Dollar",
  },
  {
    symbol: "CA$",
    name: "Canadian Dollar",
  },
  {
    symbol: "₹",
    name: "Indian Rupee",
  },
  {
    symbol: "€",
    name: "Euro",
  },
  {
    symbol: "AED",
    name: "United Arab Emirates Dirham",
  },
  {
    symbol: "Af",
    name: "Afghan Afghani",
  },
  {
    symbol: "ALL",
    name: "Albanian Lek",
  },
  {
    symbol: "AMD",
    name: "Armenian Dram",
  },
  {
    symbol: "AR$",
    name: "Argentine Peso",
  },
  {
    symbol: "AU$",
    name: "Australian Dollar",
  },
  {
    symbol: "man.",
    name: "Azerbaijani Manat",
  },
  {
    symbol: "KM",
    name: "Bosnia-Herzegovina",
  },
  {
    symbol: "Tk",
    name: "Bangladeshi Taka",
  },
  {
    symbol: "BGN",
    name: "Bulgarian Lev",
  },
  {
    symbol: "BD",
    name: "Bahraini Dinar",
  },
  {
    symbol: "FBu",
    name: "Burundian Franc",
  },
  {
    symbol: "BN$",
    name: "Brunei Dollar",
  },
  {
    symbol: "Bs",
    name: "Bolivian Boliviano",
  },
  {
    symbol: "R$",
    name: "Brazilian Real",
  },
  {
    symbol: "BWP",
    name: "Botswanan Pula",
  },
  {
    symbol: "BYR",
    name: "Belarusian Ruble",
  },
  {
    symbol: "BZ$",
    name: "Belize Dollar",
  },
  {
    symbol: "CDF",
    name: "Congolese Franc",
  },
  {
    symbol: "CHF",
    name: "Swiss Franc",
  },
  {
    symbol: "CL$",
    name: "Chilean Peso",
  },
  {
    symbol: "CN¥",
    name: "Chinese Yuan",
  },
  {
    symbol: "CO$",
    name: "Colombian Peso",
  },
  {
    symbol: "₡",
    name: "Costa Rican Colón",
  },
  {
    symbol: "CV$",
    name: "Cape Verdean Escudo",
  },
  {
    symbol: "Kč",
    name: "Czech Republic Koruna",
  },
  {
    symbol: "Fdj",
    name: "Djiboutian Franc",
  },
  {
    symbol: "Dkr",
    name: "Danish Krone",
  },
  {
    symbol: "RD$",
    name: "Dominican Peso",
  },
  {
    symbol: "DA",
    name: "Algerian Dinar",
  },
  {
    symbol: "Ekr",
    name: "Estonian Kroon",
  },
  {
    symbol: "EGP",
    name: "Egyptian Pound",
  },
  {
    symbol: "Nfk",
    name: "Eritrean Nakfa",
  },
  {
    symbol: "Br",
    name: "Ethiopian Birr",
  },
  {
    symbol: "£",
    name: "British Pound Sterling",
  },
  {
    symbol: "GEL",
    name: "Georgian Lari",
  },
  {
    symbol: "GH₵",
    name: "Ghanaian Cedi",
  },
  {
    symbol: "FG",
    name: "Guinean Franc",
  },
  {
    symbol: "GTQ",
    name: "Guatemalan Quetzal",
  },
  {
    symbol: "HK$",
    name: "Hong Kong Dollar",
  },
  {
    symbol: "HNL",
    name: "Honduran Lempira",
  },
  {
    symbol: "kn",
    name: "Croatian Kuna",
  },
  {
    symbol: "Ft",
    name: "Hungarian Forint",
  },
  {
    symbol: "Rp",
    name: "Indonesian Rupiah",
  },
  {
    symbol: "₪",
    name: "Israeli New Sheqel",
  },
  {
    symbol: "IQD",
    name: "Iraqi Dinar",
  },
  {
    symbol: "IRR",
    name: "Iranian Rial",
  },
  {
    symbol: "Ikr",
    name: "Icelandic Króna",
  },
  {
    symbol: "J$",
    name: "Jamaican Dollar",
  },
  {
    symbol: "JD",
    name: "Jordanian Dinar",
  },
  {
    symbol: "¥",
    name: "Japanese Yen",
  },
  {
    symbol: "Ksh",
    name: "Kenyan Shilling",
  },
  {
    symbol: "KHR",
    name: "Cambodian Riel",
  },
  {
    symbol: "CF",
    name: "Comorian Franc",
  },
  {
    symbol: "₩",
    name: "South Korean Won",
  },
  {
    symbol: "KD",
    name: "Kuwaiti Dinar",
  },
  {
    symbol: "KZT",
    name: "Kazakhstani Tenge",
  },
  {
    symbol: "LB£",
    name: "Lebanese Pound",
  },
  {
    symbol: "SLRs",
    name: "Sri Lankan Rupee",
  },
  {
    symbol: "Lt",
    name: "Lithuanian Litas",
  },
  {
    symbol: "Ls",
    name: "Latvian Lats",
  },
  {
    symbol: "LD",
    name: "Libyan Dinar",
  },
  {
    symbol: "MAD",
    name: "Moroccan Dirham",
  },
  {
    symbol: "MDL",
    name: "Moldovan Leu",
  },
  {
    symbol: "MGA",
    name: "Malagasy Ariary",
  },
  {
    symbol: "MKD",
    name: "Macedonian Denar",
  },
  {
    symbol: "MMK",
    name: "Myanma Kyat",
  },
  {
    symbol: "MOP$",
    name: "Macanese Pataca",
  },
  {
    symbol: "MURs",
    name: "Mauritian Rupee",
  },
  {
    symbol: "MX$",
    name: "Mexican Peso",
  },
  {
    symbol: "RM",
    name: "Malaysian Ringgit",
  },
  {
    symbol: "MTn",
    name: "Mozambican Metical",
  },
  {
    symbol: "N$",
    name: "Namibian Dollar",
  },
  {
    symbol: "₦",
    name: "Nigerian Naira",
  },
  {
    symbol: "C$",
    name: "Nicaraguan Córdoba",
  },
  {
    symbol: "Nkr",
    name: "Norwegian Krone",
  },
  {
    symbol: "NPRs",
    name: "Nepalese Rupee",
  },
  {
    symbol: "NZ$",
    name: "New Zealand Dollar",
  },
  {
    symbol: "OMR",
    name: "Omani Rial",
  },
  {
    symbol: "B/.",
    name: "Panamanian Balboa",
  },
  {
    symbol: "S/.",
    name: "Peruvian Nuevo Sol",
  },
  {
    symbol: "₱",
    name: "Philippine Peso",
  },
  {
    symbol: "PKRs",
    name: "Pakistani Rupee",
  },
  {
    symbol: "zł",
    name: "Polish Zloty",
  },
  {
    symbol: "₲",
    name: "Paraguayan Guarani",
  },
  {
    symbol: "QR",
    name: "Qatari Rial",
  },
  {
    symbol: "RON",
    name: "Romanian Leu",
  },
  {
    symbol: "din.",
    name: "Serbian Dinar",
  },
  {
    symbol: "RUB",
    name: "Russian Ruble",
  },
  {
    symbol: "RWF",
    name: "Rwandan Franc",
  },
  {
    symbol: "SR",
    name: "Saudi Riyal",
  },
  {
    symbol: "SDG",
    name: "Sudanese Pound",
  },
  {
    symbol: "Skr",
    name: "Swedish Krona",
  },
  {
    symbol: "S$",
    name: "Singapore Dollar",
  },
  {
    symbol: "Ssh",
    name: "Somali Shilling",
  },
  {
    symbol: "SY£",
    name: "Syrian Pound",
  },
  {
    symbol: "฿",
    name: "Thai Baht",
  },
  {
    symbol: "DT",
    name: "Tunisian Dinar",
  },
  {
    symbol: "T$",
    name: "Tongan Paʻanga",
  },
  {
    symbol: "TL",
    name: "Turkish Lira",
  },
  {
    symbol: "TT$",
    name: "Trinidad and Tobago Dollar",
  },
  {
    symbol: "NT$",
    name: "New Taiwan Dollar",
  },
  {
    symbol: "TSh",
    name: "Tanzanian Shilling",
  },
  {
    symbol: "₴",
    name: "Ukrainian Hryvnia",
  },
  {
    symbol: "USh",
    name: "Ugandan Shilling",
  },
  {
    symbol: "$U",
    name: "Uruguayan Peso",
  },
  {
    symbol: "UZS",
    name: "Uzbekistan Som",
  },
  {
    symbol: "Bs.F.",
    name: "Venezuelan Bolívar",
  },
  {
    symbol: "₫",
    name: "Vietnamese Dong",
  },
  {
    symbol: "FCFA",
    name: "CFA Franc BEAC",
  },
  {
    symbol: "CFA",
    name: "CFA Franc BCEAO",
  },
  {
    symbol: "YR",
    name: "Yemeni Rial",
  },
  {
    symbol: "R",
    name: "South African Rand",
  },
  {
    symbol: "ZK",
    name: "Zambian Kwacha",
  },
];
