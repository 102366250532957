import { SET_VIEW, SHOW_CUSTOMER_DETAILS } from "./setViewTypes";

//Setting the view to display in the dashboard
export const setView = (view) => {
  return {
    type: SET_VIEW,
    view,
  };
};

export const showCustomerDetails = (show) => {
  return {
    type: SHOW_CUSTOMER_DETAILS,
    show,
  };
};
