import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import userInfoReducer from "./userInfo/userInfoReducer";
import { CLEAR_STATE } from "../redux/auth/authTypes";
import setViewReducer from "./setView/setViewReducer";
import paymentHistoryReducer from "./paymentHistory/paymentHistoryReducer.js";
import dashboardReducer from "./dashboard/dashboardReducer";
import usersReducer from "./users/usersReducer";
import featureReducer from "./feature/featureReducer";
import appSettingsReducer from "./appSettings/appSettingsReducer";
import referralReducer from "./referral/referralReducer";

const appReducer = combineReducers({
  auth: authReducer,
  userInfo: userInfoReducer,
  view: setViewReducer,
  paymentHistory: paymentHistoryReducer,
  dashboard: dashboardReducer,
  users: usersReducer,
  //pricing: pricingReducer,
  appSettings: appSettingsReducer,
  feature: featureReducer,
  referralList: referralReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === CLEAR_STATE) state = undefined;
  return appReducer(state, action);
};
