import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import {
  startLoginUsingCredentials,
  clearAuthMessages,
  startLogout,
} from "../../redux/auth/authActions";
import { validateEmail } from "../../validation/auth";
import {
  Container,
  Input,
  Label,
  FormGroup,
  Row,
  Col,
  Button,
} from "reactstrap";

const Login = (props) => {
  const { auth } = props;
  const [loginData, setLoginData] = useState();
  const [errorMsg, setErrorMsg] = useState("");

  const formRef = useRef();

  useEffect(() => {
    props.clearAuthMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEmailChange = (e) => {
    const email = e.target.value;
    setLoginData({ ...loginData, email });
  };

  const onPasswordChange = (e) => {
    const password = e.target.value;
    setLoginData({ ...loginData, password });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validateEmail(loginData.email)) {
      props.startLoginUsingCredentials(loginData);
      setErrorMsg("");
      formRef.current.reset();
    } else setErrorMsg("Please enter valid email address");
  };

  return (
    <form onSubmit={onSubmit} ref={formRef}>
      <Container className="mt-3">
        <Row>
          <Col md="5">
            <FormGroup>
              <Label className="active" htmlFor="email">
                Email Address
              </Label>
              <Input
                type="email"
                id="email"
                onChange={onEmailChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="5"></Col>
          <Col md="5">
            <FormGroup>
              <Label className="active" htmlFor="password">
                Password
              </Label>
              <Input
                type="password"
                id="password"
                onChange={onPasswordChange}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        <Button color="primary">Login</Button>
        <br />
        {auth.authError ? <p>{auth.authError}</p> : null}
        {errorMsg ? <p>{errorMsg}</p> : null}
      </Container>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToState = (dispatch) => {
  return {
    startLoginUsingCredentials: (loginData) =>
      dispatch(startLoginUsingCredentials(loginData)),
    clearAuthMessages: () => dispatch(clearAuthMessages()),
    startLogout: () => dispatch(startLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToState)(Login);
