import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  Container,
  Input,
  Label,
  Col,
  Row,
  FormGroup,
  Button,
  Alert,
} from "reactstrap";
import { doAddReferral, clearMsg } from "../../redux/referral/referralActions";
import _ from "lodash";

const CreateReferral = (props) => {
  const { msg, referralList } = props;
  const [refData, setRefData] = useState({});
  const [canCreate, setCanCreate] = useState(false);
  const [codeGenerated, setCodeGenerated] = useState(true);
  const [error, setError] = useState("");
  const formRef = useRef();

  useEffect(() => {
    props.clearMsg();
    setError("");
    if (
      !_.isEmpty(refData) &&
      refData.companyName !== "" &&
      refData.companyName !== undefined &&
      refData.refCode !== "" &&
      refData.refCode !== undefined
    ) {
      setCanCreate(true);
    }
    if (
      !_.isEmpty(refData) &&
      refData.refCode !== "" &&
      refData.refCode !== undefined
    ) {
      setCodeGenerated(true);
    }
    if (
      !_.isEmpty(refData) &&
      refData.companyName !== "" &&
      refData.companyName !== undefined
    ) {
      setCodeGenerated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refData]);

  const onFormDataChanged = (e) => {
    e.preventDefault();
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setRefData({ ...refData, [fieldName]: fieldValue });
  };

  const refCodeGenerator = () => {
    const charSet = refData.companyName.replace(/\s/g, " ");
    let randomCode = "";
    for (let i = 0; i < 5; i++) {
      const randomPoz = Math.floor(Math.random() * charSet.length);
      randomCode += charSet.substring(randomPoz, randomPoz + 1);
    }
    let uniqueCode = [];
    if (!_.isEmpty(referralList)) {
      uniqueCode = referralList.filter((listItem) =>
        listItem.refCode.includes(randomCode)
      );
    }

    randomCode = randomCode + (_.size(uniqueCode) + 1);

    //console.log(randomCode.toUpperCase());
    setRefData({
      ...refData,
      refID: Math.floor(Date.now()).toString(),
      refCode: randomCode.toUpperCase(),
    });

    //return randomCode+_.sizeof(uniqueCode)+1;
  };

  const onSubmit = () => {
    let uniqueCompany = [];
    if (!_.isEmpty(referralList)) {
      uniqueCompany = referralList.filter(
        (listItem) => listItem.companyName === refData.companyName
      );
    }
    console.log(uniqueCompany);
    if (!_.isEmpty(uniqueCompany)) {
      setError("Company name already exist.");
    } else {
      props.doAddReferral({
        ...refData,
        createdDate: new Date().toISOString(),
      });
      formRef.current.reset();
      refData.refCode = "";
      setCanCreate(false);
      setCodeGenerated(true);
    }
  };

  return (
    <Fragment>
      <Container className="mt-3">
        <h5>Referral Settings</h5>
        <form ref={formRef}>
          <Row>
            <Col sm="3">
              <FormGroup>
                <Label> Company Name</Label>
                <Input
                  type="text"
                  name="companyName"
                  maxLength={60}
                  onChange={onFormDataChanged}
                  placeholder="Company Name"
                />
              </FormGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <Label> Company Contact Person</Label>
                <Input
                  type="text"
                  name="contactName"
                  onChange={onFormDataChanged}
                  placeholder="Contact Name"
                />
              </FormGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <Label> Contact Phone Number</Label>
                <Input
                  type="text"
                  name="contactPhone"
                  onChange={onFormDataChanged}
                  placeholder="Contact Number"
                />
              </FormGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <Label> Contact Email Address</Label>
                <Input
                  type="email"
                  name="contactEmail"
                  onChange={onFormDataChanged}
                  placeholder="Contact Email"
                />
              </FormGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <Label> Referral Code</Label>
                <Input
                  type="text"
                  name="refCode"
                  readOnly={true}
                  value={refData.refCode ? refData.refCode : ""}
                  //onChange={onFormDataChanged}
                />
              </FormGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <Label> Notes</Label>
                <Input
                  type="text"
                  name="notes"
                  onChange={onFormDataChanged}
                  placeholder="Notes"
                />
              </FormGroup>
            </Col>
            <Col className="mt-2">
              <Button
                className="mt-3"
                color="primary"
                onClick={refCodeGenerator}
                disabled={codeGenerated}
              >
                Generate Code
              </Button>
              <Button
                className="mt-3 ml-2"
                color="primary"
                onClick={onSubmit}
                disabled={!canCreate}
              >
                Create
              </Button>
            </Col>
          </Row>
          {msg && <Alert color="warning">{msg}</Alert>}
          {error && <Alert color="danger">{error}</Alert>}
        </form>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    msg: state.referralList.msg,
    referralList: state.referralList.referralList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddReferral: (refData) => dispatch(doAddReferral(refData)),
    clearMsg: () => dispatch(clearMsg()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateReferral);
