import React, { useEffect } from "react";
import { connect } from "react-redux";
import Login from "./auth/Login";
import { setView } from "../redux/setView/setViewActions";
import { Container } from "reactstrap";
const LandingPage = (props) => {
  useEffect(() => {
    props.setView("LOGIN");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pageColor">
      <Container className="pt-5">
        <h3>Easy Budgets Admin</h3>
        <br />
        <Login />
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setView: (view) => dispatch(setView(view)),
  };
};
export default connect(undefined, mapDispatchToProps)(LandingPage);
