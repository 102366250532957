import _ from "lodash";
export const sortEmailAndFilter = (list) => {
  const listToSort = !_.isEmpty(list) && _.uniq(list);
  return (
    !_.isEmpty(listToSort) &&
    listToSort.sort((a, b) => {
      return a > b ? 1 : -1;
    })
  );
};
