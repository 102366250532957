import React from "react";
import { connect } from "react-redux";
import { startLogout } from "../../redux/auth/authActions";
import { Navbar, Nav, NavLink, NavbarBrand } from "reactstrap";
import logo from "../images/YSSLogo01.png";

const Header = (props) => {
  return (
    <Navbar className="shadow" light expand="md">
      <NavbarBrand href="/">
        <img
          className="ml-2"
          src={logo}
          width="60"
          height="60"
          alt="Easy Budgets"
        />
      </NavbarBrand>
      <Nav className="justify-content-end" style={{ width: "100%" }}>
        <NavLink
          className="nav-btn-secondary"
          href="#"
          onClick={() => props.startLogout()}
        >
          Logout
        </NavLink>
      </Nav>
    </Navbar>
  );
};

const mapDispatchToProps = (dispatch) => ({
  startLogout: () => dispatch(startLogout()),
});

export default connect(undefined, mapDispatchToProps)(Header);
