export const SET_CURRENT_PRICE = "SET_CURRENT_PRICE";
export const SET_PRICING_HISTORY = "SET_PRICING_HISTORY";
export const UPDATE_PRICING_HISTORY = "UPDATE_CURRENT_PRICE";
export const PRICE_MSG = "PRICE_MSG";
export const SET_TRIAL_PERIOD = "SET_TRIAL_PERIOD";
export const SET_TRIAL_HISTORY = "SET_TRIAL_HISTORY";
export const UPDATE_TRIAL_HISTORY = "UPDATE_TRIAL_HISTORY";
export const TRIAL_MSG = "TRIAL_MSG";
export const SET_FEATURE_LIST = "SET_FEATURE_LIST";
export const UPDATE_FEATURE_HISTORY = "UPDATE_FEATURE_HISTORY";
export const SET_FEATURE_HISTORY = "SET_FEATURE_HISTORY";
export const FEATURE_MSG = "FEATURE_MSG";
export const APP_CONFIG_MSG = "APP_CONFIG_MSG";
