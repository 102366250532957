import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
//import numFormat from "../../selectors/numFormat";
import validateNumber from "../../validation/number";
import {
  setTrialMsg,
  startUpdateTrialPeriod,
} from "../../redux/appSettings/appSettingsActions";
import {
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  Alert,
} from "reactstrap";

const TrialSettings = (props) => {
  const { currentTrialPeriod, userInfo, msg } = props;
  const initValue = {
    date: new Date().toISOString(),
    oldTrialPeriod: currentTrialPeriod ? currentTrialPeriod : 0,
    newTrialPeriod: currentTrialPeriod ? currentTrialPeriod : 0,
    reason: "",
    modifiedBy: "",
  };
  const [trialPeriodData, setTrialPeriodData] = useState(initValue);

  useEffect(() => {
    setTrialPeriodData({
      date: new Date().toISOString(),
      oldTrialPeriod: currentTrialPeriod ? currentTrialPeriod : 0,
      newTrialPeriod: currentTrialPeriod ? currentTrialPeriod : 0,
      reason: "",
      modifiedBy: userInfo.email + "-" + userInfo.role,
    });
    props.setTrialMsg("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrialPeriod, userInfo]);

  const onTrialPeriodChange = (e) => {
    const newTrialPeriod = e.target.value;
    validateNumber(newTrialPeriod) &&
      setTrialPeriodData({ ...trialPeriodData, newTrialPeriod });
  };

  const onReasonChange = (e) => {
    const reason = e.target.value;
    setTrialPeriodData({ ...trialPeriodData, reason });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (trialPeriodData.newTrialPeriod !== trialPeriodData.oldTrialPeriod) {
      props.startUpdateTrialPeriod(trialPeriodData);
    } else props.setTrialMsg("No Changes to Update");
  };

  return (
    <Container className="mt-3">
      <h5 className="center-align">Trial Period</h5>
      <Row>
        <Col sm="2">
          <FormGroup>
            <Label>Trial Period (in Days)</Label>
            <Input
              type="text"
              value={trialPeriodData.newTrialPeriod}
              onChange={onTrialPeriodChange}
            />
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup>
            <Label>Reason for Update</Label>
            <Input
              type="text"
              value={trialPeriodData.reason}
              onChange={onReasonChange}
            />
          </FormGroup>
        </Col>
        <Col sm="2" className="mt-2">
          <Button className="mt-3" color="primary" onClick={onSubmit}>
            Update
          </Button>
        </Col>
      </Row>
      {msg && <Alert color="warning">{msg}</Alert>}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    currentTrialPeriod: state.appSettings.trialPeriod,
    msg: state.appSettings.trialmsg,
    userInfo: state.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startUpdateTrialPeriod: (trialPeriodData) =>
      dispatch(startUpdateTrialPeriod(trialPeriodData)),
    setTrialMsg: (msg) => dispatch(setTrialMsg(msg)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrialSettings);
