import {
  SET_CURRENT_PRICE,
  SET_PRICING_HISTORY,
  UPDATE_PRICING_HISTORY,
  PRICE_MSG,
  SET_TRIAL_PERIOD,
  SET_TRIAL_HISTORY,
  UPDATE_TRIAL_HISTORY,
  TRIAL_MSG,
  SET_FEATURE_LIST,
  UPDATE_FEATURE_HISTORY,
  SET_FEATURE_HISTORY,
  FEATURE_MSG,
  APP_CONFIG_MSG,
} from "./appSettingsTypes";

const initialState = {
  currentPricing: "",
  pricingHistory: [],
  trialPeriod: "",
  trialHistory: [],
  featureList: "",
  featureHistory: [],
};

const appSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PRICE:
      return { ...state, currentPricing: action.currentPricing };
    case SET_PRICING_HISTORY:
      return { ...state, pricingHistory: action.pricingHistory };
    case UPDATE_PRICING_HISTORY:
      return {
        ...state,
        pricingHistory: [...state.pricingHistory, action.pricingData],
      };
    case SET_TRIAL_PERIOD:
      return { ...state, trialPeriod: action.trialPeriod };
    case SET_TRIAL_HISTORY:
      return { ...state, trialHistory: action.trialHistory };
    case UPDATE_TRIAL_HISTORY:
      return {
        ...state,
        trialHistory: [...state.trialHistory, action.trialHistory],
      };
    case SET_FEATURE_LIST:
      return { ...state, featureList: action.featureList };
    case SET_FEATURE_HISTORY:
      return { ...state, featureHistory: action.featureHistory };
    case UPDATE_FEATURE_HISTORY:
      return {
        ...state,
        featureHistory: [...state.featureHistory, action.featureHistory],
      };
    case PRICE_MSG:
      return { ...state, pricemsg: action.pricemsg };
    case TRIAL_MSG:
      return { ...state, trialmsg: action.trialmsg };
    case FEATURE_MSG:
      return { ...state, featuremsg: action.featuremsg };
    case APP_CONFIG_MSG:
      return {
        ...state,
        appConfigMsg: action.appConfigMsg,
      };
    default:
      return state;
  }
};

export default appSettingsReducer;
