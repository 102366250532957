import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { startGetUsersList } from "../../redux/users/usersActions";
import UsersList from "../users/UsersList";
import UserSearch from "../users/UserSearch";
import { Container } from "reactstrap";

import _ from "lodash";
import UsersDetails from "../users/UsersDetails";

function DeletedUsers(props) {
  const { showCustomerDetails, usersList } = props;
  useEffect(() => {
    props.startGetUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCustomerDetails]);

  const [rows, setRows] = useState([]);
  const [updatedRows, setUpdatedRows] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(usersList)) {
      const tempUserList = usersList.filter(
        (listItem) => listItem.accountStatus === "deleted"
      );
      setRows(tempUserList);
      setUpdatedRows(tempUserList);
    }
  }, [usersList]);

  const onRowUpdate = (updatedUserList) => {
    if (!_.isEmpty(updatedUserList)) {
      setUpdatedRows(updatedUserList);
    } else setUpdatedRows([]);
  };

  return (
    <Container>
      {showCustomerDetails ? (
        <UsersDetails />
      ) : (
        <Fragment>
          <UserSearch rows={rows} onRowUpdate={onRowUpdate} />
          <UsersList rows={updatedRows} />
        </Fragment>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    showCustomerDetails: state.view.showCustomerDetails,
    usersList: state.users.usersList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startGetUsersList: () => dispatch(startGetUsersList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeletedUsers);
