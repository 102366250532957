import React, { Fragment } from "react";
import moment from "moment";
import _ from "lodash";
import { Container, Form, Label } from "reactstrap";

const UserInfoDisplay = (props) => {
  const { userDetails } = props;
  return (
    <Fragment>
      <Container className="border border-primary mt-2 p-2">
        <Label className="border border-primary mt-2 p-2">
          <strong>User Basic Details</strong>
        </Label>
        <Form inline>
          <h6>
            First Name:<strong>{userDetails.firstName}</strong>
          </h6>
          <hr />
          <h6>
            Last Name:<strong>{userDetails.lastName}</strong>
          </h6>
          <hr />
          <h6>
            Display Name:<strong>{userDetails.displayName}</strong>
          </h6>
          <hr />
          <h6>
            City:<strong>{userDetails.city}</strong>
          </h6>
          <hr />
          <h6>
            State:<strong>{userDetails.state}</strong>
          </h6>
          <hr />
          <h6>
            Country:<strong>{userDetails.country}</strong>
          </h6>
        </Form>
        <Form inline>
          <h6>
            Email Verified:
            <strong>{userDetails.emailVerified ? "Yes" : "No"}</strong>
          </h6>
          <hr />
          <h6>
            Date Created Account:
            <strong>{moment(userDetails.date).format()}</strong>
          </h6>
          <hr />
          <h6>
            Date T&C Accepted:
            <strong>{moment(userDetails.dateAcceptedTandC).format()}</strong>
          </h6>
        </Form>
        <hr />
        <Label className="border border-primary mt-2 p-2">
          <strong>Inactive Account</strong>
        </Label>
        <Form inline>
          {userDetails.accountStatus === "deleted" && (
            <h6>
              Date Account Delete:
              <strong>
                {moment(userDetails.dateAccountDeleted).format("DD-MMM-YYYY")}
              </strong>
            </h6>
          )}
          <hr />
          {userDetails.isEnabled !== true && (
            <h6>
              Account Disabled On Date:
              <strong>
                {moment(userDetails.dateAccountDisabled).format("DD-MMM-YYYY")}
              </strong>
            </h6>
          )}
          <hr />
          {userDetails.accountStatus === "cancelled" && (
            <h6>
              Date Account Cancelled:
              <strong>{moment(userDetails.dateCancelled).format()}</strong>
            </h6>
          )}
        </Form>

        <hr />
        <Label className="border border-primary mt-2 p-2">
          <strong>Scheduled Remainders</strong>
        </Label>
        <Form inline>
          {userDetails.isRemainderSet === true &&
            !_.isEmpty(userDetails.remainder) && (
              <Fragment>
                <h6>
                  Reminder Set : {userDetails.isRemainderSet ? "True" : "False"}
                </h6>
                {userDetails.remainder.map((item, id) => {
                  return (
                    <div key={item.remainderID} className="ml-3">
                      <h6>
                        Reminder Name:
                        <strong>
                          {userDetails.remainder[id].remainderName}
                        </strong>
                      </h6>
                    </div>
                  );
                })}
              </Fragment>
            )}
        </Form>

        <hr />
        <Label className="border border-primary mt-2 p-2">
          <strong>Payment Details</strong>
        </Label>
        <Form inline>
          <h6>
            Last Payment Date:
            <strong> {moment(userDetails.lastPaymentDate).format()}</strong>
          </h6>
          <hr />
          <h6>
            Next Payment Date:
            <strong> {moment(userDetails.nextPaymentDate).format()}</strong>
          </h6>
          <hr />
          <h6>
            Payment Gateway:
            <strong> {userDetails.paymentGateway}</strong>
          </h6>
        </Form>

        <hr />
        <Label className="border border-primary mt-2 p-2">
          <strong>Subscription Details</strong>
        </Label>
        <Form inline>
          <h6>
            Subscribed On:
            <strong> {moment(userDetails.subscribedOn).format()}</strong>
          </h6>
          <hr />
          <h6>
            Subscription Amount:
            <strong> {userDetails.subscriptionAmount}</strong>
          </h6>
          <hr />
          <h6>
            Subscription Frequency:
            <strong> {userDetails.subscriptionFrequency}</strong>
          </h6>
        </Form>

        <hr />
        <Form inline>
          <h6>
            Subscription Started On:
            <strong>
              {moment(userDetails.subscriptionStartDate).format()}
            </strong>
          </h6>
          <hr />
          <h6>
            Subscription Expires On:
            <strong>{moment(userDetails.subscriptionEndDate).format()}</strong>
          </h6>
          <hr />
          <h6>
            Trial Started On:
            <strong>{moment(userDetails.trialStartDate).format()}</strong>
          </h6>
          <hr />
          <h6>
            Trial End On:
            <strong>{moment(userDetails.trialEndDate).format()}</strong>
          </h6>
          <hr />
        </Form>

        <hr />
        <Label className="border border-primary mt-2 p-2">
          <strong>Account Statistics</strong>
        </Label>
        <Form inline>
          <h6>
            Numbers of Budgets Created:
            <strong>{userDetails.noOfBudgetsCreated}</strong>
          </h6>
          <hr />
          <h6>
            Numbers of Accounts Created:
            <strong>{userDetails.noOfAccounts}</strong>
          </h6>
          <hr />
        </Form>

        <hr />
        <Label className="border border-primary mt-2 p-2">
          <strong>Refund Details</strong>
        </Label>
        <Form inline>
          <h6>
            Refund Date:
            <strong>
              {moment(userDetails.refundDate).format("DD-MMM-YYYY")}
            </strong>
          </h6>
          <hr />
          <h6>
            Refund Amount:
            <strong>{userDetails.refundAmount / 100}</strong>
          </h6>
          <hr />
          <h6>
            Refund Reason:
            <strong>{userDetails.refundReason}</strong>
          </h6>
        </Form>
      </Container>
    </Fragment>
  );
};

export default UserInfoDisplay;
