import React from "react";
import { connect } from "react-redux";
import {
  DASHBOARD,
  PAYMENTS,
  USERS,
  APP_SETTINGS,
  REPORTS,
  DELUSERS,
  REF_SETTINGS,
} from "../viewTypes";
import { setView } from "../../redux/setView/setViewActions";
import { authMessage } from "../../redux/auth/authActions";
import { List } from "reactstrap";

const SideView = (props) => {
  const { view, role } = props;

  const viewChange = (view) => {
    props.setView(view);
    props.authMessage();
  };

  return (
    <div>
      <br />
      <List className="m-3" type="unstyled">
        <li
          className={
            view === DASHBOARD ? "sidenav-btn-selected" : "sidenav-btn"
          }
          onClick={() => props.setView(DASHBOARD)}
        >
          Dashboard
        </li>
        <br />
        <li
          className={view === PAYMENTS ? "sidenav-btn-selected" : "sidenav-btn"}
          onClick={() => props.setView(PAYMENTS)}
        >
          {role === "superAdmin" && <span>Payments</span>}
        </li>
        <br />
        <li
          className={view === USERS ? "sidenav-btn-selected" : "sidenav-btn"}
          onClick={() => viewChange(USERS)}
        >
          Users
        </li>
        <br />
        {role === "superAdmin" && (
          <li
            className={
              view === DELUSERS ? "sidenav-btn-selected" : "sidenav-btn"
            }
            onClick={() => viewChange(DELUSERS)}
          >
            {role === "superAdmin" && <span>Deleted Users</span>}
          </li>
        )}
        <br />
        <li
          className={
            view === APP_SETTINGS ? "sidenav-btn-selected" : "sidenav-btn"
          }
          onClick={() => viewChange(APP_SETTINGS)}
        >
          App Settings
        </li>
        <br />
        <li
          className={
            view === REF_SETTINGS ? "sidenav-btn-selected" : "sidenav-btn"
          }
          onClick={() => viewChange(REF_SETTINGS)}
        >
          Referral Settings
        </li>
        <br />
        <li
          className={view === REPORTS ? "sidenav-btn-selected" : "sidenav-btn"}
          onClick={() => viewChange(REPORTS)}
        >
          Reports
        </li>
      </List>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    view: state.view.mainView,
    role: state.userInfo.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setView: (view) => {
      dispatch(setView(view));
    },
    authMessage: () => {
      dispatch(authMessage(""));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideView);
