import { firebase } from "../../firebase/firebase";
import {
  startGetUserInfo,
  startUpdateUserInfoEV,
} from "../userInfo/userInfoActions.js";
import _ from "lodash";

export const login = (uid, emailVerified) => ({
  type: "LOGIN",
  uid,
  emailVerified,
});

export const loginError = (err) => ({
  type: "LOGIN_ERROR",
  err,
});

export const clearState = () => ({
  type: "CLEAR_STATE",
});

export const authMessage = (authMessage) => ({
  type: "AUTH_MESSAGE",
  authMessage,
});

export const logout = () => ({
  type: "LOGOUT",
});

export const clearAuthMessages = () => ({
  type: "CLEAR_AUTH_MESSAGES",
});

export const startLoginUsingCredentials = (credentials = {}) => {
  //console.log("Credentials", credentials);
  return (dispatch) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .catch((err) => dispatch(loginError(err)));
  };
};

export const startLogout = () => {
  return (dispatch) => {
    //console.log("We came here...");
    return firebase
      .auth()
      .signOut()
      .then(() => dispatch(clearState()));
  };
};

export const loadApp = (user) => {
  return (dispatch, getState) => {
    if (user && user.emailVerified) {
      dispatch(login(user.uid, user.emailVerified));
      dispatch(startGetUserInfo()).then(() => {
        const userInContext = getState().userInfo;
        if (!_.isEmpty(userInContext)) {
          user.emailVerified !== userInContext.emailVerified &&
            dispatch(startUpdateUserInfoEV(user.emailVerified, user.email));
        }
      });
    }
    return;
  };
};
