import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  //AgGridColumn,
  AgGridReact,
} from "ag-grid-react";
import moment from "moment";
import sortByDate from "../../selectors/sortByDate";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

const FeatureHistory = (props) => {
  const { featureHistory } = props;

  // const [gridApi, setGridApi] = useState(null);
  // const [gridColumnApi, setGridColumnApi] = useState(null);

  //console.log("Pricing History", pricingHistory);

  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([]);

  function onGridReady(params) {
    // setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
  }

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Date",
        field: "date",
        resizable: true,
      },
      {
        headerName: "Login Allowed",
        field: "allowLogin",
        resizable: true,
        width: 120,
      },
      {
        headerName: "Sign-Up Allowed",
        field: "allowSignUp",
        resizable: true,
        width: 120,
      },
      {
        headerName: "Export Allowed",
        field: "allowExport",
        resizable: true,
        width: 120,
      },
      {
        headerName: "Import Allowed",
        field: "allowImport",
        resizable: true,
        width: 120,
      },
      {
        headerName: "Allowed Budgets",
        field: "allowedBudgets",
        resizable: true,
        width: 120,
      },
      {
        headerName: "Allowed Accounts",
        field: "allowedAccounts",
        resizable: true,
        width: 120,
      },
      {
        headerName: "Modified By",
        field: "modifiedBy",
        resizable: true,
        width: 240,
      },
    ]);
  }, []);

  useEffect(() => {
    const temp =
      featureHistory.length > 0
        ? featureHistory.map((history) => ({
            date: moment(history.date).format(),
            allowLogin: history.allowLogin,
            allowSignUp: history.allowSignUp,
            allowExport: history.allowExport,
            allowImport: history.allowImport,
            allowedBudgets: history.allowedBudgets,
            allowedAccounts: history.allowedAccounts,
            modifiedBy: history.modifiedBy,
          }))
        : "";
    setRowData(temp);
  }, [featureHistory]);

  return (
    <div
      className="ag-theme-balham left-align"
      style={{ height: 240, width: "100%", boxSizing: "border-box" }}
    >
      <AgGridReact
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        rowData={rowData}
        enableFilter={true}
        enableSorting={true}
        pagination="true"
        paginationPageSize="5"
      ></AgGridReact>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    featureHistory: sortByDate(state.appSettings.featureHistory),
  };
};

export default connect(mapStateToProps)(FeatureHistory);
