import React from "react";
import { connect } from "react-redux";
import { categoryList, appCategoryList } from "../data/defaultCategories";
import { appPayeesList } from "../data/defaultPayees";
import { dateFormatForBudget, dateFormatForImport } from "../data/dateFormat";
import { accountTypes } from "../data/accountTypes";
import { Container, Col, Button, Alert, Row } from "reactstrap";
import { currencies } from "../data/currencies";
import { countries } from "../data/countries";
import { usStates } from "../data/usStates";
import { canadaStates } from "../data/canadaStates";
import { indianStates } from "../data/indianStates";
import { accountCancelReasons } from "../data/accountCancelReasons";

import {
  setDefaultConfigs,
  setDefaultCategories,
  setDefaultAppCategories,
  setDefaultPayees,
  setDateFormat,
  setCurrencies,
  setAccountTypes,
  setCountries,
  setUSStates,
  setCanadaStates,
  setIndianStates,
  setCancelReasons,
  setMessages,
  setReservedKeyWord,
} from "../../redux/appSettings/appSettingsActions";
import { reservedKeyWords } from "../data/reservedKeyWords";
import { messages } from "../data/messages";

const OneTimeConfigSettings = (props) => {
  const { msg } = props;
  let categories = [];
  let subcategories = [];
  let appCategories = [];
  let appSubcategories = [];
  let appPayees = [];
  categoryList.map((category, id) => {
    const tempID = Math.floor((Math.random() + id) * Date.now()).toString();
    categories.push({
      categoryID: tempID,
      categoryName: category.categoryName,
      order: id + 100,
      show: true,
      active: true,
    });
    category.subcategoryNames.map((subcategory, id) => {
      const tempID1 = Math.floor((Math.random() + id) * Date.now()).toString();
      subcategories.push({
        parentCategoryID: tempID,
        subcategoryID: tempID1,
        subcategoryName: subcategory,
        order: id + 100,
        show: true,
        active: true,
      });
      return "";
    });
    return "";
  });

  appCategoryList.map((category, id) => {
    const tempID = Math.floor((Math.random() + id) * Date.now()).toString();
    appCategories.push({
      categoryID: tempID,
      categoryName: category.categoryName,
      order: id + 100,
      active: true,
    });
    category.subcategoryNames.map((subcategory, id) => {
      const tempID1 = Math.floor((Math.random() + id) * Date.now()).toString();
      appSubcategories.push({
        parentCategoryID: tempID,
        subcategoryID: tempID1,
        subcategoryName: subcategory,
        order: id + 100,
        active: true,
      });
      return "";
    });
    return "";
  });

  appPayeesList.map((payee, id) => {
    const tempID = Math.floor((Math.random() + id) * Date.now()).toString();
    appPayees.push({ payeeID: tempID, payeeName: payee.payeeName });
    return "";
  });

  /* const onSubmit = () => {
    props.setDefaultConfigs(
      categories,
      subcategories,
      appCategories,
      appSubcategories,
      appPayees,
      //dateFormat,
      currencies,
      accountTypes
    );
  }; */

  const setDefaultCategories = () => {
    props.setDefaultCategories(categories, subcategories);
  };

  // const setDefaultSubcategories = () => {
  //   console.log("Sub", subcategories);
  //   props.setDefaultSubcategories(subcategories);
  // };

  const setDefaultAppCategories = () => {
    props.setDefaultAppCategories(appCategories, appSubcategories);
  };

  // const setDefaultAppSubcategories = () => {
  //   props.setDefaultAppSubcategories(appSubcategories);
  // };

  const setDefaultPayees = () => {
    props.setDefaultPayees(appPayees);
  };

  const setDateFormat = () => {
    props.setDateFormat(dateFormatForBudget, dateFormatForImport);
  };

  const setCurrencies = () => {
    props.setCurrencies(currencies);
  };

  const setAccountTypes = () => {
    props.setAccountTypes(accountTypes);
  };

  const setCountries = () => {
    props.setCountries(countries);
  };

  const setUSStates = () => {
    props.setUSStates(usStates);
  };

  const setCanadaStates = () => {
    props.setCanadaStates(canadaStates);
  };

  const setIndianStates = () => {
    props.setIndianStates(indianStates);
  };

  const setCancelReasons = () => {
    props.setCancelReasons(accountCancelReasons);
  };

  const setMessages = () => {
    props.setMessages(messages);
  };

  const setReservedKeyWord = () => {
    props.setReservedKeyWord(reservedKeyWords);
  };

  return (
    <Container className="mt-3">
      <h5>OnTime Application Configuration Setting</h5>
      <Row>
        <Col sm="4" className="mt-2">
          <Button
            className="mt-3"
            color="primary"
            onClick={setDefaultCategories}
          >
            Default Categories & Subcategories
          </Button>
        </Col>
        {/*<Col sm="3" className="mt-2">
          <Button
            className="mt-3"
            color="primary"
            onClick={setDefaultSubcategories}
          >
            Default Subcategories
          </Button>
  </Col>*/}
        <Col sm="4" className="mt-2">
          <Button
            className="mt-3"
            color="primary"
            onClick={setDefaultAppCategories}
          >
            Default App Categories & Subcategories
          </Button>
        </Col>
        {/*<Col sm="3" className="mt-2">
          <Button
            className="mt-3"
            color="primary"
            onClick={setDefaultAppSubcategories}
          >
            Default App Subcategories
          </Button>
</Col>*/}
        <Col sm="4" className="mt-2">
          <Button className="mt-3" color="primary" onClick={setDefaultPayees}>
            Default Payees
          </Button>
        </Col>
        <Col sm="4" className="mt-2">
          <Button className="mt-3" color="primary" onClick={setDateFormat}>
            Date Format
          </Button>
        </Col>
        <Col sm="4" className="mt-2">
          <Button className="mt-3" color="primary" onClick={setCurrencies}>
            Currencies
          </Button>
        </Col>
        <Col sm="4" className="mt-2">
          <Button className="mt-3" color="primary" onClick={setAccountTypes}>
            Account Types
          </Button>
        </Col>
        <hr />

        <Col sm="4" className="mt-2">
          <Button className="mt-3" color="primary" onClick={setCountries}>
            Countries
          </Button>
        </Col>
        <Col sm="4" className="mt-2">
          <Button className="mt-3" color="primary" onClick={setUSStates}>
            US States
          </Button>
        </Col>
        <Col sm="4" className="mt-2">
          <Button className="mt-3" color="primary" onClick={setCanadaStates}>
            Canada States
          </Button>
        </Col>
        <Col sm="4" className="mt-2">
          <Button className="mt-3" color="primary" onClick={setIndianStates}>
            Indian States
          </Button>
        </Col>
        <Col sm="4" className="mt-2">
          <Button className="mt-3" color="primary" onClick={setCancelReasons}>
            Reason For Cancelling
          </Button>
        </Col>
        <Col sm="4" className="mt-2">
          <Button className="mt-3" color="primary" onClick={setMessages}>
            Messages
          </Button>
        </Col>
        <Col sm="4" className="mt-2">
          <Button className="mt-3" color="primary" onClick={setReservedKeyWord}>
            Reserved Key Words
          </Button>
        </Col>
      </Row>
      <br />
      {msg && <Alert color="warning">{msg}</Alert>}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    msg: state.appSettings.appConfigMsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDefaultCategories: (categories, subcategories) =>
      dispatch(setDefaultCategories(categories, subcategories)),
    // setDefaultSubcategories: (subcategories) =>
    //   dispatch(setDefaultSubcategories(subcategories)),
    setDefaultAppCategories: (appCategories, appSubcategories) =>
      dispatch(setDefaultAppCategories(appCategories, appSubcategories)),
    // setDefaultAppSubcategories: (appSubcategories) =>
    //   dispatch(setDefaultAppSubcategories(appSubcategories)),
    setDefaultPayees: (appPayees) => dispatch(setDefaultPayees(appPayees)),
    setDateFormat: (dateFormatForBudget, dateFormatForImport) =>
      dispatch(setDateFormat(dateFormatForBudget, dateFormatForImport)),
    setCurrencies: (currencies) => dispatch(setCurrencies(currencies)),
    setAccountTypes: (accountTypes) => dispatch(setAccountTypes(accountTypes)),
    setCountries: (countries) => dispatch(setCountries(countries)),
    setUSStates: (usStates) => dispatch(setUSStates(usStates)),
    setCanadaStates: (canadaStates) => dispatch(setCanadaStates(canadaStates)),
    setIndianStates: (indianStates) => dispatch(setIndianStates(indianStates)),
    setCancelReasons: (reasons) => dispatch(setCancelReasons(reasons)),
    setMessages: (messages) => dispatch(setMessages(messages)),
    setReservedKeyWord: (keywords) => dispatch(setReservedKeyWord(keywords)),

    setDefaultConfigs: (
      categories,
      subcategories,
      appCategories,
      appSubcategories,
      appPayees,
      dateFormat,
      currencies,
      accountTypes
    ) =>
      dispatch(
        setDefaultConfigs(
          categories,
          subcategories,
          appCategories,
          appSubcategories,
          appPayees,
          dateFormat,
          currencies,
          accountTypes
        )
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OneTimeConfigSettings);
