import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  //AgGridColumn,
  AgGridReact,
} from "ag-grid-react";
import moment from "moment";
import numFormat from "../../selectors/numFormat";
import sortByDate from "../../selectors/sortByDate";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

const PricingHistory = (props) => {
  const { pricingHistory } = props;

  // const [gridApi, setGridApi] = useState(null);
  // const [gridColumnApi, setGridColumnApi] = useState(null);

  //console.log("Pricing History", pricingHistory);

  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([]);

  function onGridReady(params) {
    // setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
  }

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Date",
        field: "date",
        resizable: true,
      },
      {
        headerName: "New Monthly Price",
        field: "newMonthlyPrice",
        resizable: true,
        width: 150,
      },
      {
        headerName: "Old Monthly Price",
        field: "oldMonthlyPrice",
        resizable: true,
        width: 150,
      },
      {
        headerName: "New Yearly Price",
        field: "newYearlyPrice",
        resizable: true,
        width: 150,
      },
      {
        headerName: "Old Yearly Price",
        field: "oldYearlyPrice",
        resizable: true,
        width: 150,
      },
      {
        headerName: "New Tax",
        field: "newTax",
        resizable: true,
        width: 150,
      },
      {
        headerName: "Old Tax",
        field: "oldTax",
        resizable: true,
        width: 150,
      },
      { headerName: "Reason", field: "reason", resizable: true, width: 150 },
      {
        headerName: "Modified By",
        field: "modifiedBy",
        resizable: true,
        width: 240,
      },
    ]);
  }, []);

  useEffect(() => {
    const temp =
      pricingHistory.length > 0
        ? pricingHistory.map((pricingHistoryItem) => ({
            date: moment(pricingHistoryItem.date).format(),
            newMonthlyPrice: numFormat(pricingHistoryItem.newMonthlyPrice),
            oldMonthlyPrice: numFormat(pricingHistoryItem.oldMonthlyPrice),
            newYearlyPrice: numFormat(pricingHistoryItem.newYearlyPrice),
            oldYearlyPrice: numFormat(pricingHistoryItem.oldYearlyPrice),
            newTax: pricingHistoryItem.newTax / 100 + "%",
            oldTax: pricingHistoryItem.oldTax / 100 + "%",
            reason: pricingHistoryItem.reason,
            modifiedBy: pricingHistoryItem.modifiedBy,
          }))
        : "";
    setRowData(temp);
  }, [pricingHistory]);

  return (
    <div
      className="ag-theme-balham left-align"
      style={{ height: 240, width: "100%", boxSizing: "border-box" }}
    >
      <AgGridReact
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        rowData={rowData}
        enableFilter={true}
        enableSorting={true}
        pagination="true"
        paginationPageSize="5"
      ></AgGridReact>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pricingHistory: sortByDate(state.appSettings.pricingHistory),
  };
};

export default connect(mapStateToProps)(PricingHistory);
