import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import LandingPage from "../components/LandingPage";
import ContainerPage from "../components/container/ContainerPage";
import PageNotFound from "../components/PageNotFound";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

export const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <PrivateRoute exact path="/" component={ContainerPage} />
      <PublicRoute exact path="/login" component={LandingPage} />
      <Route component={PageNotFound} />
    </Switch>
  </Router>
);

export default AppRouter;
