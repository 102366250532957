export const messages = {
  activateSubscription: `Currently your subscription is in "Cancelled" status. You will not be able to access your data after`,

  autoRenewMessage: `(Please note your card will be automatically charged on your due date)`,

  cancelSubscription: `Canceling your subscription will allow you to use Easy Budgets through the end of your current subscription period. After that period ends, you will not be able to use the application and there will be no auto renewal or charge. You can reactivate your account anytime.`,

  cardAuthorize: `I authorize Easy Budgets to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the Terms and Conditions agreed by me with Easy Budgets. I also authorize Easy Budgets to charge my card as per the subscription plan selected by me until I cancel the subscription.`,

  changeEmail:
    "Upon changing the email address to this account, you will be logged out of the application and you will receive an verification email for reauthentication. After you complete the email verification process, you can relogin using new email address aand valid password.",

  deleteAccount:
    "Delete your account if you no longer want to use Easy Budgets to manage your budget. This will delete all your data permanently including your budgets, accounts and transactions. We will refund for the remaining period on pro-rated basis. Full amount will be refunded, if the account is deleted within 24 hours from the time the subscription amount is paid. Your refund will show up on the card that was originally charged, though it might take up to 10 business days.",

  emailNotVerified: `Please verify your email by clicking the link in the email you received. Please check your spam folder if you haven't received in your inbox.`,

  importUnsavedAlert: `The data in the file will not be saved, if you move away without review. Review the mapping to automatically save your data from the file. However after review, anytime you can import your transactions to an account by visiting Import History.`,

  importWarning: `Please be aware that during import process, the values populated in the fields Paid To/By, Categories and Subcategories from the uploaded file will be automatically created if they are not part of your budget(s).`,

  signInUp:
    "`<p>The Application is under maintenance to serve you better!!!</p><p>Should be back shortly.</p>`",
  unsavedAlert:
    "You have unsaved data and trying to move away from this page. How do you want to proceed?",
  userDisabled:
    "You have no permission to access the application, please reach out to Support team.",
};
