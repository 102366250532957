import React, { Fragment } from "react";

import CreateReferral from "./CreateReferral";
import ReferralTable from "./ReferralTable";

const RefSettings = () => {
  return (
    <Fragment>
      <CreateReferral />
      <ReferralTable />
    </Fragment>
  );
};

export default RefSettings;
