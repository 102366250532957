import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { validateEmail } from "../../validation/auth";
import { searchUserByEmail } from "../../redux/users/usersActions";
import { Row, Col, Label, Input, Button, Alert } from "reactstrap";
import DropdownList from "react-widgets/DropdownList";

const UserSearch = (props) => {
  const { rows } = props;

  const [email, setEmail] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [searchBy, setSearchBy] = useState("Email");

  const searchByOptions = ["Email", "UserID", "Dates"];

  const formRef = useRef();

  const onEmailChange = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onSearchChange = (value) => {
    //console.log("value", value);
    setErrorMsg("");
    setSearchBy(value);
  };

  const onSearchHandle = (e) => {
    e.preventDefault();

    if (searchBy === "Email" && validateEmail(email)) {
      //console.log("rows", rows);
      console.log("Emai entered", email);
      const userListByEmail = rows.filter((data) => data.email === email);

      //console.log("List", userListByEmail);
      props.onRowUpdate(userListByEmail);
      //props.searchUserByEmail(email);
      setErrorMsg("");
      formRef.current.reset();
    } else {
      setErrorMsg("Invalid email address!!!");
    }
  };

  return (
    <div>
      <form onSubmit={onSearchHandle} ref={formRef} className="my-3">
        <Row>
          <Col sm="2">
            <Label>Search Option</Label>
            <DropdownList
              className="custom-dropdown"
              data={searchByOptions}
              onChange={onSearchChange}
              value={searchBy}
            />
          </Col>
          {searchBy === "Email" && (
            <Col sm="3">
              <Label htmlFor="email">Email Address</Label>
              <Input type="email" id="email" onChange={onEmailChange} />
            </Col>
          )}

          <Col sm="3" className="mt-2">
            <Button
              className="mt-4"
              disabled={searchBy !== "Email" ? true : false}
              color="primary"
              onChange={onSearchHandle}
              size="sm"
            >
              Search
            </Button>
          </Col>
          <Col className="mt-3">
            {errorMsg && <Alert color="danger">{errorMsg}</Alert>}
            {searchBy === "Dates" && (
              <Alert color="info">Yet to enable the feature</Alert>
            )}
            {searchBy === "UserID" && (
              <Alert color="info">Yet to enable the feature</Alert>
            )}
          </Col>
        </Row>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchUserByEmail: (email) => dispatch(searchUserByEmail(email)),
    // searchPaymentHistoryByDates: (startDate, endDate) =>
    //   dispatch(searchPaymentHistoryByDates(startDate, endDate)),
  };
};

export default connect(undefined, mapDispatchToProps)(UserSearch);
