import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { userListBy, getAdminUsers } from "../../redux/users/usersActions";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import DropdownList from "react-widgets/DropdownList";
import moment from "moment";

const UserActions = (props) => {
  const { rows } = props;

  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const [days, setDays] = useState();
  const [userType, setUserType] = useState();

  const [tableTopic, setTableTopic] = useState("List of All Users");

  const subscripedUsers = () => {
    //const searchBy = { key: "subscriptionStatus", value: "subscribed" };
    //props.userListBy(searchBy);
    const updatedList = rows.filter(
      (data) =>
        data.accountStatus === "active" &&
        data.subscriptionStatus === "subscribed"
    );
    props.onRowUpdate(updatedList);
    setTableTopic("List of Active Subscribed Users");
  };

  const trialUsers = () => {
    // const searchBy = { key: "subscriptionStatus", value: "trial" };
    // props.userListBy(searchBy);
    const updatedList = rows.filter(
      (data) =>
        data.accountStatus === "active" && data.subscriptionStatus === "trial"
    );
    props.onRowUpdate(updatedList);
    setTableTopic("List of Active Trial Users");
  };

  const subscripedExpiredUsers = () => {
    // const searchBy = { key: "accountStatus", value: "expired" };
    // props.userListBy(searchBy);
    const updatedList = rows.filter((data) => data.accountStatus === "expired");
    props.onRowUpdate(updatedList);
    setTableTopic("List of Expired Subscribed and Trial Users");
  };

  const subscripedCancelledUsers = () => {
    // const searchBy = { key: "accountStatus", value: "cancelled" };
    // props.userListBy(searchBy);
    const updatedList = rows.filter(
      (data) => data.accountStatus === "cancelled"
    );
    props.onRowUpdate(updatedList);
    setTableTopic("List of Users Cancelled the Account");
  };

  const adminUsers = () => {
    //props.getAdminUsers();
    const updatedList = rows.filter(
      (data) => data.role === "admin" || data.role === "superAdmin"
    );
    props.onRowUpdate(updatedList);
    setTableTopic("List of Admin Users");
  };

  const resetAll = () => {
    props.onRowUpdate(rows);
  };

  const onList = () => {
    console.log("Diff", moment().diff(moment("23-May-2021"), "days"));
    const updatedList = rows.filter(
      (data) =>
        data.accountStatus === "active" &&
        data.subscriptionStatus === userType.toLowerCase() &&
        (userType.toLowerCase === "trial"
          ? Math.abs(moment().diff(moment(data.trialEndDate), "days")) <= days
          : Math.abs(moment().diff(moment(data.subscriptionEndDate), "days")) <=
            days)
    );
    props.onRowUpdate(updatedList);
    setTableTopic(`List of ${userType} Users Expiring in ${days} Days `);
  };

  const validDays = (days) => {
    //console.log("Value came in", valueEntered);
    const validDays = /^\d{0,2}?$/;
    const validLength = 2;
    return !days ||
      (days.match(validDays) && days.length <= validLength && days >= 0)
      ? true
      : false;
  };

  const daysChanged = (e) => {
    const daysEntered = e.target.value;
    validDays(daysEntered) && setDays(daysEntered);
  };

  const onUserTypeSelected = (value) => {
    setUserType(value);
  };

  return (
    <Fragment>
      <Form inline>
        <h5
          className="border border-primary p-2 mr-1 mt-1"
          style={{ minWidth: "36rem" }}
        >
          {tableTopic}
        </h5>
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle outline color="success" caret>
            List Users By
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={subscripedUsers}>
              Active Subscriped Users
            </DropdownItem>
            <DropdownItem onClick={trialUsers}>Trial Users</DropdownItem>
            <DropdownItem onClick={subscripedExpiredUsers}>
              Expired Users
            </DropdownItem>
            <DropdownItem onClick={subscripedCancelledUsers}>
              Subscription Cancelled Users
            </DropdownItem>
            <DropdownItem onClick={adminUsers}>Admin Users</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
        <Button outline color="success" className="m-1" onClick={resetAll}>
          Reset All
        </Button>
        <FormGroup className="p-1 border border-primary">
          <DropdownList
            className="custom-dropdown-small"
            data={["Trial", "Subscribed"]}
            placeholder="Select"
            onChange={onUserTypeSelected}
            value={userType ? userType : ""}
          />
          <Label className="mx-2"> Users Expiring in</Label>
          <Input
            style={{ height: "1.8rem", width: "3rem", fontSize: ".9rem" }}
            onChange={daysChanged}
            value={days ? days : ""}
          />
          <Label className="mx-2">days</Label>
          <Button
            color="primary"
            size="sm"
            disabled={userType && days ? false : true}
            onClick={onList}
          >
            List
          </Button>
        </FormGroup>
      </Form>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    userListBy: (searchBy) => dispatch(userListBy(searchBy)),
    getAdminUsers: () => dispatch(getAdminUsers()),
  };
};

export default connect(undefined, mapDispatchToProps)(UserActions);
