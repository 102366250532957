import React from "react";

const Reports = () => {
  return (
    <div className="section">
      <div className="container">
        <h5>Under Construction</h5>
      </div>
    </div>
  );
};

export default Reports;
