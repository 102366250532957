export const validateEmail = (valueEntered) => {
  //console.log("Value came in", valueEntered);
  const validName = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
  //const validLength = 18;
  return !valueEntered || valueEntered.match(validName) ? true : false;
};

export const validateName = (valueEntered) => {
  //console.log("Value came in", valueEntered);
  const validName = /^(?! )[a-z0-9-\s]{0,255}$/i;
  const validLength = 24;
  return !valueEntered ||
    (valueEntered.match(validName) && valueEntered.length <= validLength)
    ? true
    : false;
};
