import { SET_USER_STAT, SET_PAYMENT_STAT } from "./dashboardTypes";
import database from "../../firebase/firebase";
import { sumOf } from "../../selectors/sumOf";
import _ from "lodash";
import moment from "moment";
import { setPaymentSummary } from "../paymentHistory/paymentHistoryActions";

export const setUserStat = (userStat) => {
  console.log("User Stat", userStat);
  return {
    type: SET_USER_STAT,
    userStat,
  };
};

export const setPaymentStat = (paymentStat) => {
  return {
    type: SET_PAYMENT_STAT,
    paymentStat,
  };
};

export const startGettingUserStat = () => {
  return (dispatch, getState) => {
    return (
      database
        .collection("adminInfo")
        .doc("userStat")
        //.orderBy("paymentDate", "desc")
        //.limit(20)
        .get()
        .then((snapshot) => {
          const userStat = snapshot.data();
          // snapshot.forEach((childSnapshot) => {
          //   userStat.push({
          //     ...childSnapshot.data(),
          //   });
          // });
          dispatch(setUserStat(userStat));
          //console.log("User Stat", userStat);
        })
        .catch((err) => {
          console.log("Error Retriving user Statistics:", err.message);
        })
    );
  };
};

export const startGettingPaymentStat = () => {
  return (dispatch, getState) => {
    return database
      .collection("adminInfo")
      .doc("paymentInfo")
      .get()
      .then((snapshot) => {
        const paymentStat = snapshot.data();
        dispatch(setPaymentStat(paymentStat));
      })
      .catch((err) => {
        console.log("Error Retriving payment Statistics:", err.message);
      });
  };
};

export const doGetUserStat = () => {
  let users = [];
  return (dispatch, getState) => {
    return (
      database
        .collection("userData")
        //.where("accountStatus", "==", "active")
        .get()
        .then((snapshot) => {
          //console.log("User...", snapshot.docs[0].data());
          snapshot.forEach((childSnapshot) => {
            users.push({
              ...childSnapshot.data(),
            });
          });
          const totalUsers = users.length || 0;
          const totalActiveUsers =
            users.filter((user) => user.accountStatus === "active").length || 0;
          const totalActiveSubscribedUsers =
            users.filter(
              (user) =>
                user.accountStatus === "active" &&
                user.subscriptionStatus === "subscribed"
            ).length || 0;
          const totalActiveTrialUsers =
            users.filter(
              (user) =>
                user.accountStatus === "active" &&
                user.subscriptionStatus === "trial"
            ).length || 0;
          const totalDisabledUsers =
            users.filter((user) => user.isEnabled === false).length || 0;
          const totalExpiredUsers =
            users.filter((user) => user.accountStatus === "expired").length ||
            0;
          const totalDeletedUsers =
            users.filter((user) => user.accountStatus === "deleted").length ||
            0;
          const userStat = {
            totalUsers,
            totalActiveUsers,
            totalActiveSubscribedUsers,
            totalActiveTrialUsers,
            totalDisabledUsers,
            totalExpiredUsers,
            totalDeletedUsers,
          };
          dispatch(setUserStat(userStat));
          // console.log("User Stat", userStat);
          // console.log(
          //   `Total Users: ${totalUsers}; Total Active Users: ${totalActiveUsers};Total Active Sub Users: ${totalActiveSubscribedUsers}`
          // );
          // console.log(
          //   `Total Trial Users: ${totalActiveTrialUsers}; Total Disabled Users: ${totalDisabledUsers}`
          // );
          // console.log(`Total Expired Users: ${totalExpiredUsers}`);
          // console.log(
          //   `Total Number of Users Deleted the Account: ${totalDeletedUsers}`
          // );
        })
        .catch((err) => {
          console.log("Error Retriving user Statistics:", err.message);
        })
    );
  };
};

export const doGetPaymentStat = () => {
  //const payStat = [];
  return (dispatch, getState) => {
    return database
      .collection("adminInfo")
      .doc("paymentSummary")
      .get()
      .then((snapshot) => {
        const paymentSummary = snapshot.data().payments;
        if (!_.isEmpty(paymentSummary)) {
          const thisMonth = moment().format("MMM_YYYY");
          const lastMonth = moment().subtract(1, "months").format("MMM_YYYY");
          const listOfAllAmount = paymentSummary.map((pay) => pay.amount);
          const totalPayment = sumOf(listOfAllAmount);

          const listOfThisMonthAmount = paymentSummary.map(
            (pay) =>
              moment(pay.paymentDate).format("MMM_YYYY") === thisMonth &&
              pay.amount
          );
          const thisMonthPayment = sumOf(listOfThisMonthAmount);

          const listOfLastMonthAmount = paymentSummary.map(
            (pay) =>
              moment(pay.paymentDate).format("MMM_YYYY") === lastMonth &&
              pay.amount
          );
          const lastMonthPayment = sumOf(listOfLastMonthAmount);

          dispatch(
            setPaymentStat({ totalPayment, thisMonthPayment, lastMonthPayment })
          );
          dispatch(setPaymentSummary(paymentSummary));
        }
      })
      .catch((err) => {
        console.log("Error Retriving payment Statistics:", err.message);
      });
  };
};
