import { SET_USERINFO } from "./userInfoTypes";
import database from "../../firebase/firebase";

export const setUserInfo = (userInfo) => {
  //console.log("User Info", userInfo);
  return {
    type: SET_USERINFO,
    userInfo,
  };
};

export const startGetUserInfo = () => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;
    //console.log("User UID", uid);
    return database
      .collection(`userData`)
      .doc(`${uid}`)
      .get()
      .then((user) => {
        //console.log("User", user.data());
        user.exists
          ? dispatch(setUserInfo(user.data()))
          : dispatch(setUserInfo(null));
      })
      .catch((error) => console.log("Error", error));

    // .onSnapshot(
    //   (snapshot) =>
    //     snapshot.exists
    //       ? dispatch(setUserInfo(snapshot.data()))
    //       : dispatch(setUserInfo(null)),
    //   (err) => {
    //     console.log(err.message);
    //   }
    // );
  };
};

//update the userInfo of email verified after checking if changed
export const startUpdateUserInfoEV = (emailVerified, email) => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;
    return database
      .collection(`userData`)
      .doc(`${uid}`)
      .update({ emailVerified: emailVerified, email: email })
      .then(() => {
        dispatch(startGetUserInfo());
      })
      .catch((err) => {
        console.log("Error", err.message);
      });
  };
};
