import { SET_FEATURE_LIST, MSG } from "./featureTypes";

const initialState = {
  featureList: {},
  msg: "",
};

const paymentHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FEATURE_LIST:
      return { ...state, featureList: action.featureList };
    case MSG:
      return { ...state, msg: action.msg };
    default:
      return state;
  }
};

export default paymentHistoryReducer;
