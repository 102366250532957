import { SET_REFERRAL, UPDATE_REFERRAL, MSG, CLEAR_MSG } from "./referralTypes";
import database from "../../firebase/firebase";
import firebase from "firebase";

export const setReferralList = (referralList) => {
  return {
    type: SET_REFERRAL,
    referralList,
  };
};

export const updateReferralList = (refData) => {
  return {
    type: UPDATE_REFERRAL,
    refData,
  };
};

export const setMsg = (msg) => {
  return {
    type: MSG,
    msg,
  };
};

export const clearMsg = () => {
  return {
    type: CLEAR_MSG,
  };
};

export const doAddReferral = (refData) => {
  return (dispatch) => {
    return database
      .collection("adminInfo")
      .doc("referralList")
      .update({
        referralList: firebase.firestore.FieldValue.arrayUnion(refData),
      })
      .then(() => {
        console.log("added ref");
        dispatch(updateReferralList(refData));
        dispatch(setMsg("Successfully created the referral record."));
      })
      .catch(
        (
          err //console.log("Error adding..", err.message)
        ) =>
          dispatch(
            setMsg(`Issue updating the referral record - ${err.message}`)
          )
      );
  };
};

export const doGetReferralList = () => {
  return (dispatch) => {
    return database
      .collection(`adminInfo`)
      .doc("referralList")
      .get()
      .then((snapshot) => {
        const referralList = snapshot.data().referralList;

        dispatch(setReferralList(referralList));
      })
      .catch((err) => {
        console.log("Error retriving referral details.", err.message);
      });
  };
};

export const doUpdateReferralList = (newReferralList) => {
  return (dispatch) => {
    return database
      .collection(`adminInfo`)
      .doc("referralList")
      .set({ referralList: newReferralList })
      .then(() => {
        dispatch(setReferralList(newReferralList));
      })
      .catch((err) => {
        console.log("Error updating referral list.", err.message);
      });
  };
};
