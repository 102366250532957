import {
  SET_USERS_LIST,
  SET_CUSTOMER_CONTEXT,
  MSG,
  SET_CUSTOMER_INFO_CHANGE_HISTORY,
  UPDATE_CUSTOMER_INFO_CHANGE_HISTORY,
} from "./usersTypes";
import database from "../../firebase/firebase";
import firebase from "firebase";

export const setUsersList = (usersList) => {
  return {
    type: SET_USERS_LIST,
    usersList,
  };
};

export const setCustomerInContext = (customerDetails) => {
  return {
    type: SET_CUSTOMER_CONTEXT,
    customerDetails,
  };
};

export const setMsg = (msg) => {
  return {
    type: MSG,
    msg,
  };
};

export const setCustomerInfoChangeHistory = (history) => {
  return {
    type: SET_CUSTOMER_INFO_CHANGE_HISTORY,
    history,
  };
};

export const updateCustomerInfoChangeHistory = (updatedUserInfo) => {
  return {
    type: UPDATE_CUSTOMER_INFO_CHANGE_HISTORY,
    updatedUserInfo,
  };
};

export const startGetUsersList = () => {
  return (dispatch) => {
    return database
      .collection(`userData`)
      .orderBy("dateAcceptedTandC", "desc")
      .limit(20)
      .get()
      .then((snapshot) => {
        const usersList = [];
        //console.log(snapshot.size);
        snapshot.forEach((childSnapshot) => {
          usersList.push({
            id: childSnapshot.id,
            ...childSnapshot.data(),
          });
        });
        //console.log(usersList);
        dispatch(setUsersList(usersList));
      })
      .catch((err) => {
        console.log("Error Retriving User List:", err.message);
      });
  };
};

export const searchUserByEmail = (email) => {
  //console.log("Email", email);
  return (dispatch) => {
    return database
      .collection("userData")
      .where("email", "==", email)
      .limit(1)
      .get()
      .then((snapshot) => {
        let cid = "";
        const customerDetails = [];
        snapshot.forEach((childSnapshot) => {
          customerDetails.push({
            id: childSnapshot.id,
            ...childSnapshot.data(),
          });
          cid = childSnapshot.id;
        });
        dispatch(setUsersList(customerDetails));
        dispatch(setCustomerInContext(customerDetails));
        dispatch(getCustomerInfoChangeHistory(cid));
      })
      .catch((err) => {
        dispatch(setMsg(`Error Retriving Customer Details: ${err.message}`));
      });
  };
};

export const getCustomerInfoChangeHistory = (cid) => {
  return (dispatch) => {
    return database
      .collection(`userData/${cid}/history`)
      .doc("userInfoHistory")
      .get()
      .then((snapshot) => {
        const temp = snapshot.data().history;
        dispatch(setCustomerInfoChangeHistory(temp));
      })
      .catch((err) => {
        dispatch(
          setMsg(`Error Retriving Customer Info Change History: ${err.message}`)
        );
      });
  };
};

export const updateUserDetails = (updatedUserDetails, userDetails) => {
  return (dispatch) => {
    return database
      .collection("userData")
      .doc(`${updatedUserDetails.id}`)
      .update(updatedUserDetails)
      .then(() => {
        dispatch(addUserInfoHistory(userDetails));
        dispatch(setMsg("Successfully Updated"));
      })
      .catch((err) => {
        dispatch(setMsg(`Error Updating the Values: ${err.message}`));
      });
  };
};

export const updateUserRefundDetails = (userDetailsToUpdate, userDetails) => {
  return (dispatch) => {
    //console.log("User Details", userDetailsToUpdate);
    dispatch(updateUserDetails(userDetailsToUpdate, userDetails))
      .then(() => {
        dispatch(setCustomerInContext(userDetailsToUpdate));
        dispatch(setMsg("Successfully Updated"));
      })
      .catch((err) => {
        dispatch(setMsg(`Error Updating the Values: ${err.message}`));
      });
  };
};

export const addUserInfoHistory = (userDetails) => {
  return (dispatch) => {
    return database
      .collection(`userData/${userDetails.id}/history`)
      .doc(`userInfoHistory`)
      .update({
        history: firebase.firestore.FieldValue.arrayUnion(userDetails),
      })
      .then(() => {
        //console.log("User data".updatedUserInfo);
        dispatch(updateCustomerInfoChangeHistory(userDetails));
        dispatch(setMsg("Successfully Updated"));
      })
      .catch((err) => {
        dispatch(setMsg(`Error Updating the Values: ${err.message}`));
      });
  };
};

export const userListBy = (searchBy) => {
  return (dispatch) => {
    return database
      .collection("userData")
      .where(searchBy.key, "==", searchBy.value)
      .get()
      .then((snapshot) => {
        const customerDetails = [];
        snapshot.forEach((childSnapshot) => {
          customerDetails.push({
            id: childSnapshot.id,
            ...childSnapshot.data(),
          });
        });
        dispatch(setUsersList(customerDetails));
      })
      .catch((err) => {
        console.log("Error Retriving Customer Details", err.message);
      });
  };
};

export const getAdminUsers = () => {
  return (dispatch) => {
    return database
      .collection("userData")
      .where("role", "in", ["superAdmin", "admin"])
      .get()
      .then((snapshot) => {
        const customerDetails = [];
        snapshot.forEach((childSnapshot) => {
          customerDetails.push({
            id: childSnapshot.id,
            ...childSnapshot.data(),
          });
        });
        dispatch(setUsersList(customerDetails));
      })
      .catch((err) => {
        console.log("Error Retriving Customer Details", err.message);
      });
  };
};
