export const dateFormatForBudget = [
  { format: "DD-MMM-YYYY", example: "12-Jan-2021" },
  { format: "DD/MMM/YYYY", example: "12/Jan/2021" },
  { format: "DD/MM/YYYY", example: "12/01/2021" },
  { format: "DD-MM-YYYY", example: "12-01-2021" },
  { format: "MM/DD/YYYY", example: "01/12/2021" },
  { example: "01-12-2021", format: "MM-DD-YYYY" },
  { example: "2021-01-12", format: "YYYY-MM-DD" },
  { example: "2021/01/12", format: "YYYY/MM/DD" },
  { example: "2021-Jan-12", format: "YYYY-MMM-DD" },
];

export const dateFormatForImport = [
  { format: "DD-MMM-YYYY", example: "12-Jan-2021" },
  { format: "DD/MMM/YYYY", example: "12/Jan/2021" },
  { format: "DD/MM/YYYY", example: "12/01/2021" },
  { format: "DD-MM-YYYY", example: "12-01-2021" },
  { format: "MM/DD/YYYY", example: "01/12/2021" },
  { example: "01-12-2021", format: "MM-DD-YYYY" },
  { example: "2021-01-12", format: "YYYY-MM-DD" },
  { example: "2021/01/12", format: "YYYY/MM/DD" },
  { example: "2021-Jan-12", format: "YYYY-MMM-DD" },
  { example: "Jan 12, 2021", format: "MMM D, YYYY" },
  { example: "1/12/2021", format: "M/D/YYYY" },
  { example: "12/1/2021", format: "D/M/YYYY" },
  { example: "2021/1/12", format: "YYYY/M/D" },
  { example: "Jan122021", format: "MMMDDYYYY" },
  { example: "12Jan2021", format: "DDMMMYYYY" },
  { example: "2021Jan12", format: "YYYYMMMDD" },
];
