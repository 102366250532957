import { SET_VIEW, SHOW_CUSTOMER_DETAILS } from "./setViewTypes";

const initialState = {
  mainView: "",
  showCustomerDetails: false,
};

const setViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIEW:
      return { ...state, mainView: action.view, showCustomerDetails: false };
    case SHOW_CUSTOMER_DETAILS:
      return { ...state, showCustomerDetails: action.show };
    default:
      return state;
  }
};

export default setViewReducer;
