import React from "react";

const ReferralItemsReadOnly = (props) => {
  const { listItem } = props;
  return (
    <tr>
      <td>{listItem.companyName}</td>
      <td>{listItem.contactName}</td>
      <td>{listItem.contactPhone}</td>
      <td>{listItem.contactEmail}</td>
      <td>{listItem.refCode}</td>
      <td>{listItem.notes}</td>
      <td style={{ width: "6rem" }}>
        <i
          className="bi-pencil-square"
          style={{ fontSize: "1.2rem" }}
          title="Edit"
          onClick={() => props.editReferral(listItem.refID)}
        ></i>
      </td>
    </tr>
  );
};

export default ReferralItemsReadOnly;
